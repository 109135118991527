

export const updateCanteenProductPrices = (state, priceData) => {

    const canData = { ...state.canteenData }
    const product = canData?.canteenProduct?.find(item => item.productId === priceData.productId)



    if (product) {
        product.prices = priceData?.prices
        product.finalPrices = [...product?.finalPrices?.map(item => {
            const productPrice = priceData?.prices?.find(i => i.vatCode.toString() === item.vatCode.toString())
            return {
                ...item,
                priceExcludingVat: productPrice?.priceExcludingVat,
                priceIncludingVat: productPrice?.priceIncludingVat,
                status: productPrice?.status
            }
        })]
        return canData
    }
    return state.canteenData

}

export const resetToGlobalPrices = (state, resetData) => {
    const canData = { ...state.canteenData }
    const product = canData?.canteenProduct?.find(item => item.productId === resetData.productId)

    if (product) {
        product.prices = []
        product.finalPrices = [...product?.finalPrices?.map(item => {
            const productPrice = product?.product?.productPrices?.find(i => i.vatCode.toString() === item.vatCode.toString())
            return {
                ...item,
                priceExcludingVat: productPrice?.priceExcludingVat,
                priceIncludingVat: productPrice?.priceIncludingVat,
                status: productPrice?.status
            }
        })]
        return canData
    }
    return state.canteenData
}

export const updateCanteenProducts = (state, canteenPrdData) => {
    if (!canteenPrdData) return state.canteenData

    const canData = { ...state.canteenData }
    canData['canteenProduct'] = canteenPrdData


    return canData
}
