import {
    ADD_CROCKERY,
    ADD_CROCKERY_SUCCESS,
    ADD_CROCKERY_ERROR,
    DELETE_CROCKERY,
    DELETE_CROCKERY_SUCCESS,
    DELETE_CROCKERY_ERROR,
    EDIT_CROCKERY,
    EDIT_CROCKERY_SUCCESS,
    EDIT_CROCKERY_ERROR,
} from '../types';

export const crockeryReducer = (state, action) => {
    switch (action.type) {
        case ADD_CROCKERY:
            return { ...state, success: false, crockeryLoading: true, error: null };
        case ADD_CROCKERY_SUCCESS:
            return {
                ...state,
                crockeryLoading: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case ADD_CROCKERY_ERROR:
            return {
                ...state,
                crockeryLoading: false,
                success: false,
                message: null,
                error: action.payload,
            };


        case DELETE_CROCKERY:
            return { ...state, success: false, crockeryLoading: true, error: null };
        case DELETE_CROCKERY_SUCCESS:
            return {
                ...state,
                crockeryLoading: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case DELETE_CROCKERY_ERROR:
            return {
                ...state,
                crockeryLoading: false,
                success: false,
                message: null,
                error: action.payload,
            };


        case EDIT_CROCKERY:
            return { ...state, success: false, crockeryLoading: true, error: null };
        case EDIT_CROCKERY_SUCCESS:
            return {
                ...state,
                crockeryLoading: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case EDIT_CROCKERY_ERROR:
            return {
                ...state,
                crockeryLoading: false,
                success: false,
                message: null,
                error: action.payload,
            };

        default:
            return null;
    }
}; 