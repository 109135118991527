import * as types from '../types';


// Subsidy Agreements
export const getSubsidyAgreements = (canteenId) => ({
    type: types.GET_SUBSIDY_AGREEMENTS,
    payload: { canteenId },
});

export const getSubsidyAgreementsSuccess = (subsidyData) => ({
    type: types.GET_SUBSIDY_AGREEMENTS_SUCCESS,
    payload: subsidyData,
});

export const getSubsidyAgreementsError = (error) => ({
    type: types.GET_SUBSIDY_AGREEMENTS_ERROR,
    payload: error,
});

export const addSubsidyAgreement = (subsidyData, callback) => ({
    type: types.ADD_SUBSIDY_AGREEMENT,
    payload: { subsidyData, callback },
});

export const addSubsidyAgreementSuccess = (success, message, subsidyData) => ({
    type: types.ADD_SUBSIDY_AGREEMENT_SUCCESS,
    payload: { success, message, subsidyData },
});

export const addSubsidyAgreementError = (error) => ({
    type: types.ADD_SUBSIDY_AGREEMENT_ERROR,
    payload: error,
});

export const getSubsidyAgreementDetails = (subsidyId, callback) => ({
    type: types.GET_SUBSIDY_AGREEMENT_DETAILS,
    payload: { subsidyId, callback },
});

export const getSubsidyAgreementDetailsSuccess = (subsidyData) => ({
    type: types.GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS,
    payload: subsidyData,
});

export const getSubsidyAgreementDetailsError = (error) => ({
    type: types.GET_SUBSIDY_AGREEMENT_DETAILS_ERROR,
    payload: error,
});

export const deleteSubsidyAgreement = (subsidyId, callback) => ({
    type: types.DELETE_SUBSIDY_AGREEMENT,
    payload: { subsidyId, callback },
});

export const deleteSubsidyAgreementSuccess = (success, message, subsidyId) => ({
    type: types.DELETE_SUBSIDY_AGREEMENT_SUCCESS,
    payload: { success, message, subsidyId },
});

export const deleteSubsidyAgreementError = (error) => ({
    type: types.DELETE_SUBSIDY_AGREEMENT_ERROR,
    payload: error,
});
