import {
    GET_WEIGHTED_LIST,
    GET_WEIGHTED_LIST_SUCCESS,
    GET_WEIGHTED_LIST_ERROR,
    EDIT_WEIGHTED_LIST,
    EDIT_WEIGHTED_LIST_SUCCESS,
    EDIT_WEIGHTED_LIST_ERROR,
} from '../types';

export const weightedListReducer = (state, action) => {
    switch (action.type) {
        case GET_WEIGHTED_LIST:
            return {
                ...state,
                weightedList: null,
                weightedListLoading: true,
                success: false,
                error: null
            };
        case GET_WEIGHTED_LIST_SUCCESS:
            return {
                ...state,
                weightedList: action.payload,
                weightedListLoading: false,
                success: true,
                error: null
            };
        case GET_WEIGHTED_LIST_ERROR:
            return {
                ...state,
                weightedList: null,
                weightedListLoading: false,
                success: false,
                error: action.payload
            };


        case EDIT_WEIGHTED_LIST:
            return { ...state, success: false, weightedListLoading1: true, error: null };
        case EDIT_WEIGHTED_LIST_SUCCESS:
            return {
                ...state,
                weightedListLoading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case EDIT_WEIGHTED_LIST_ERROR:
            return {
                ...state,
                weightedListLoading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        default:
            return null;
    }
}; 