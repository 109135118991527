import * as Mui from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import Breadcrumb from "../../../components/breadcrumb";
import {
  AutocompleteSelectField,
  InputField, InputPasswordField, StyledSwitch,
} from "../../../components/form";
import { MUIRichText } from "../../../components/rich-text-editor";
import { IconAdd, IconClose, IconTrash } from "../../../components/svg";
import { editSetting, getCountryList, getPOSTerminal, getSetting, getUser } from "../../../reduxs/actions";

// import Dropzone from "../../../components/dropzone";
// import { IconClose, IconColorsiteTitle } from "../../../components/svg";
const getSchema = (isSuperAdmin, t) => {
  if (isSuperAdmin) {
    return Yup.object().shape({
      eatIn: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      takeAway: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      siteTitle: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      phone: Yup.string().matches(
        /^(?=.*[0-9])[- +()0-9]+$/,
        t("AUTH.VALIDATION.INVALID_FIELD")
      ),
      streetAddress: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      postalCode: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      city: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      country: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
    });
  } else {

    return Yup.object().shape({
      eatIn: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      takeAway: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      siteTitle: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),
      phone: Yup.string().matches(
        /^(?=.*[0-9])[- +()0-9]+$/,
        t("AUTH.VALIDATION.INVALID_FIELD")
      ),
      streetAddress: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      postalCode: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      city: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),
      country: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD")),

      netsUsername: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),

      netsPassword: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),

      netsUrl: Yup.string()
        .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .nullable(),

      dailyReportEmail: Yup.array(
        Yup.string()
          .email(t("AUTH.VALIDATION.INVALID_EMAIL"))
          .nullable()
      )

      // primaryColor: Yup.string()
      //   .required(t("AUTH.VALIDATION.REQUIRED_FIELD"))
      //   .nullable(),
    });
  }
}

class Setting extends Component {
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
    this.state = {
      buttonAnchor: null,
      posTerminalPopover: false
    }
  }

  componentDidMount() {
    this.props.getSetting();
    this.props.getCountryList();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.props.getUser(currentUser?.id);
    this.props.getPOSTerminal();
  }

  handlePosTerminalPopoverOpen(event) {
    this.setState({
      buttonAnchor: event.currentTarget,
      posTerminalPopover: true
    })
  }
  handlePosTerminalPopoverClose() {
    this.setState({
      buttonAnchor: null,
      posTerminalPopover: false
    })
  }

  onOpenDropzone = (event) => {
    this.setState({ anchorEl: event.currentTarget, openDropzone: true });
  };

  onCloseDropzone = () => {
    this.setState({ anchorEl: null, openDropzone: false });
  };

  onSubmit = () => {
    if (!this.props.settingData?.orgId === 'Master') {
      this.formikRef.current.setFieldValue('dailyReportEmail', this.formikRef.current.values.dailyReportEmail.filter((item) => item !== '' && item !== null))
      this.formikRef.current.setFieldValue('monthlyReportEmail', this.formikRef.current.values.monthlyReportEmail.filter((item) => item !== '' && item !== null))
    }
    if (!this.props.loading) {
      this.props.editSetting(this.formikRef.current.values);
    }
  };

  componentDidUpdate(previousProps) {
    if (previousProps.settingData !== this.props.settingData) {
      this.setState({ attachment: this.props.settingData?.logo || [] });
    }
  }


  render() {
    const { t, settingData } = this.props;

    const isSuperAdmin = this.props.settingData?.orgId === 'Master'

    return (
      <>
        <Breadcrumb
          title={t("PERMISSIONS.modules.siteSetting")}
          paths={[
            {
              title: t("PERMISSIONS.sections.setting"),
            },
          ]}
        />

        <Formik
          innerRef={this.formikRef}
          enableReinitialize={true}
          initialValues={
            isSuperAdmin ? {
              siteTitle: settingData?.siteTitle || "",
              phone: settingData?.phone || "",
              // address: settingData?.address || "",
              streetAddress: settingData?.streetAddress || "",
              postalCode: settingData?.postalCode || "",
              city: settingData?.city || "",
              country: settingData?.country ? +settingData?.country : 160,
              location: settingData?.location || "",
              eatIn: settingData?.eatIn || "",
              takeAway: settingData?.takeAway || "",
              termsConditions: settingData?.termsConditions || ""
            }
              : {
                eatIn: settingData?.eatIn || "",
                takeAway: settingData?.takeAway || "",
                siteTitle: settingData?.siteTitle || "",
                phone: settingData?.phone || "",
                // address: settingData?.address || "",
                streetAddress: settingData?.streetAddress || "",
                postalCode: settingData?.postalCode || "",
                city: settingData?.city || "",
                country: settingData?.country ? +settingData?.country : 160,
                location: settingData?.location || "",
                netsUsername: settingData?.netsUsername || "",
                netsPassword: settingData?.netsPassword || "",
                netsUrl: settingData?.netsUrl || "",
                enableEmailNotifications: settingData?.enableEmailNotifications || "0",
                dailyReportEmail: settingData?.dailyReportEmail || [],
                monthlyReportEmail: settingData?.monthlyReportEmail || [],
                termsConditions: settingData?.termsConditions || ""
                // primaryColor: settingData?.primaryColor || "",
                // primaryColorHue:
                //   settingData?.primaryColorHue || "",
                // primaryColorSaturation:
                //   settingData?.primaryColorSaturation || "",
                // primaryColorLight:
                //   settingData?.primaryColorLight || "",
                // logo: settingData?.logo || [],
              }
          }
          validationSchema={getSchema(isSuperAdmin, this.props.t)}
          onSubmit={this.onSubmit}
        >
          {(props) => (
            <Form className="default-form">
              <Mui.Card className="default-card spacing-md">
                {/* {((window.location.hostname ===
                  `api.${process.env.REACT_APP_API_BASE_URL}`)||
                  (window.location.hostname === 'localhost'
                    ))
                  ? (
                  <>
                    <Mui.Typography
                      component="h4"
                      variant="h4"
                      className="mb-3 font-weight-normal"
                    >
                      {t("PERMISSIONS.sections.setting")}
                    </Mui.Typography>
                    <Mui.Grid container spacing={4}>
                      <Mui.Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className="form-group"
                      >
                        <InputField
                          name="siteTitle"
                          type="text"
                          label={t("SETTING.FORM.SITE_TITLE")}
                          placeholder={t("SETTING.FORM.PLACEHOLDER.SITE_TITLE")}
                        />
                      </Mui.Grid>
                      <Mui.Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className="form-group"
                      >
                        <InputField
                          name="phone"
                          type="text"
                          label={t("SETTING.FORM.PHONE")}
                          placeholder={t("SETTING.FORM.PLACEHOLDER.PHONE")}
                        />
                      </Mui.Grid>
                      <Mui.Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className="form-group"
                      >
                        <InputField
                          name="address"
                          type="text"
                          label={t("SETTING.FORM.ADDRESS")}
                          placeholder={t("SETTING.FORM.PLACEHOLDER.ADDRESS")}
                        />
                      </Mui.Grid>
                      <Mui.Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className="form-group"
                      >
                        <InputField
                          name="location"
                          type="text"
                          label={t("SETTING.FORM.LOCATION")}
                          placeholder={t("SETTING.FORM.PLACEHOLDER.LOCATION")}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </>
                ) : ( */}
                <>
                  <Mui.Typography
                    component="h4"
                    variant="h4"
                    className="mb-1 font-weight-normal"
                  >
                    {t("SETTING.VAT_LABEL")}
                  </Mui.Typography>
                  <Mui.Typography
                    component="p"
                    variant="body1"
                    className="mb-3 text-color-grey"
                  >
                    {t("SETTING.VAT_INFO_LABEL")}
                  </Mui.Typography>

                  <Mui.Grid container spacing={4}>
                    <Mui.Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                      className="form-group"
                    >
                      <InputField
                        name="eatIn"
                        type="text"
                        label={t("SETTING.FORM.EAT_IN_VAT")}
                        placeholder={t("SETTING.FORM.PLACEHOLDER.EAT_IN_VAT")}
                      />
                    </Mui.Grid>

                    <Mui.Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                      className="form-group"
                    >
                      <InputField
                        name="takeAway"
                        type="text"
                        label={t("SETTING.FORM.TAKE_AWAY_VAT")}
                        placeholder={t(
                          "SETTING.FORM.PLACEHOLDER.TAKE_AWAY_VAT"
                        )}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </>
              </Mui.Card>
              <Mui.Card className="default-card spacing-md mt-2">
                <Mui.Typography
                  component="h4"
                  variant="h4"
                  className="mb-3 font-weight-normal"
                >
                  {t("PERMISSIONS.sections.setting")}
                </Mui.Typography>
                <Mui.Grid container spacing={4}>
                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="siteTitle"
                      type="text"
                      label={t("SETTING.FORM.ORGANIZATION_TITLE")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.ORGANIZATION_TITLE")}
                    />
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="phone"
                      type="text"
                      label={t("SETTING.FORM.PHONE")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.PHONE")}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container spacing={4} className="mt-4">
                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="streetAddress"
                      type="text"
                      label={t("SETTING.FORM.STREET_ADDRESS")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.STREET_ADDRESS")}
                    />
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="postalCode"
                      type="text"
                      label={t("SETTING.FORM.POSTAL_CODE")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.POSTAL_CODE")}
                    />
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="city"
                      type="text"
                      label={t("SETTING.FORM.CITY")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.CITY")}
                    />
                  </Mui.Grid>

                  <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >

                    <AutocompleteSelectField
                      name={`country`}
                      label={t(
                        "SETTING.FORM.PLACEHOLDER.COUNTRY"
                      )}
                      options={this.props.countryList}
                    />
                  </Mui.Grid>
                  {/* <Mui.Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="form-group"
                  >
                    <InputField
                      name="location"
                      type="text"
                      label={t("SETTING.FORM.LOCATION")}
                      placeholder={t("SETTING.FORM.PLACEHOLDER.LOCATION")}
                    />
                  </Mui.Grid> */}
                </Mui.Grid>
                {!isSuperAdmin && <>
                  <Mui.Divider light={true} className="mt-3 mb-5" />
                  <Mui.Typography
                    component="h4"
                    variant="h4"
                    className="mb-2 font-weight-normal"
                  >
                    {t("SETTING.NETS_SETTING")}
                  </Mui.Typography>
                  <Mui.Grid container spacing={4}>
                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className="form-group"
                    >
                      <InputField
                        name="netsUsername"
                        type="text"
                        label={`${t("SETTING.FORM.MERCHANT")} ${t("SETTING.FORM.USERNAME")}`}
                        placeholder={t("SETTING.FORM.PLACEHOLDER.NETS_USERNAME")}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className="form-group"
                    >
                      <InputPasswordField
                        name="netsPassword"
                        label={`${t("SETTING.FORM.MERCHANT")} ${t("SETTING.FORM.PASSWORD")}`}
                        placeholder={t("SETTING.FORM.PLACEHOLDER.NETS_PASSWORD")}
                      // showStartAdornment={false}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="form-group"
                    >
                      <InputField
                        name="netsUrl"
                        label={t("SETTING.FORM.URL")}
                        placeholder={t("SETTING.FORM.PLACEHOLDER.NETS_URL")}
                      // showStartAdornment={false}
                      />
                    </Mui.Grid>
                    <Mui.Grid
                      item
                      xs={12}
                      className="form-group"
                    >
                      <Mui.Box display={"flex"} gridGap={5} alignItems={'center'}>
                        <Mui.Typography
                          component="h6"
                          variant="h6"
                          className="font-weight-normal"
                        >
                          <span
                            style={{ color: "#3957e8" }}
                            className="pointer"
                            onClick={(e) => {
                              this.handlePosTerminalPopoverOpen(e)
                            }}>{t("COMMON.FORM.ACTION.VIEW")}{" "}</span>
                          {t("SETTING.TERMINAL_LIST")}
                        </Mui.Typography>
                        {this.props.loading1 && <Mui.CircularProgress size={15} />}
                      </Mui.Box>
                      <Mui.Popover
                        id={`pos-terminal-popover`}
                        classes={{ root: "kebab-dropdown" }}
                        anchorEl={this.state.buttonAnchor}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        open={this.state.posTerminalPopover}
                        onClose={() => {
                          this.handlePosTerminalPopoverClose()
                        }}
                      >
                        <Mui.Box className="pl-2 pr-2 pt-1 pb-1" style={{ minWidth: "300px", }}>
                          <Mui.Box className="mb-1" display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Mui.Typography
                              variant="h5"
                              component="h5"
                              className="font-weight-normal"
                            >{t("SETTING.TERMINAL_LIST")}
                            </Mui.Typography>
                            <Mui.IconButton
                              className=" svg-size-xs"
                              onClick={() => {
                                this.handlePosTerminalPopoverClose()
                              }}
                            >
                              <IconClose />
                            </Mui.IconButton>
                          </Mui.Box>
                          <Mui.Box style={{ border: '1px solid #E9ECEF', borderRadius: '1rem' }}>
                            <Mui.Grid container style={{ borderBottom: '1px solid #E9ECEF' }} className="p-1">
                              <Mui.Grid item xs={6}>
                                <Mui.Typography
                                  variant="h6"
                                  component="h6"
                                  className=" font-weight-normal"
                                >
                                  {t("KIOSK.TERMINAL_ID")}
                                </Mui.Typography>
                              </Mui.Grid>
                              <Mui.Grid item xs={6}>
                                <Mui.Typography
                                  variant="h6"
                                  component="h6"
                                  className=" font-weight-normal"
                                >
                                  {t("KIOSK.FORM.KIOSK_ID")}
                                </Mui.Typography>
                              </Mui.Grid>
                            </Mui.Grid>
                            {this.props?.posTerminals?.map((item) => {
                              return <Mui.Grid key={item?.terminalId} container className="p-1">
                                <Mui.Grid item xs={6}>
                                  <Mui.Typography
                                    variant="h6"
                                    component="h6"
                                    className=" font-weight-normal"
                                  >
                                    {item?.terminalId ?? '-'}
                                  </Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={6}>
                                  <Mui.Typography
                                    variant="h6"
                                    component="h6"
                                    className=" font-weight-normal"
                                  >
                                    {item?.pos?.number ?? '-'}
                                  </Mui.Typography>
                                </Mui.Grid>
                              </Mui.Grid>
                            })}
                          </Mui.Box>
                        </Mui.Box>
                      </Mui.Popover>
                    </Mui.Grid>
                  </Mui.Grid>
                </>}
                {!isSuperAdmin &&
                  <>
                    <Mui.Divider light={true} className="mt-3 mb-5" />
                    <Mui.Typography
                      component="h4"
                      variant="h4"
                      className="font-weight-normal"
                    >
                      {t('SETTING.REPORT_SETTING')}
                    </Mui.Typography>
                    <Mui.Grid container spacing={4}>
                      <Mui.Grid
                        item
                        xs={12}
                        className="form-group"
                      >
                        <Mui.Typography
                          className="MuiFormLabel-root mb-1"
                          style={{
                            color: 'rgba(154, 161, 167, 0.5)',
                            fontWeight: 500, fontSize: '1.6rem',
                            letterSpacing: 0.8
                          }}
                        >
                          {t('SETTING.ENABLE_EMAIL_NOTIFICATIONS')}
                        </Mui.Typography>
                        <StyledSwitch
                          checked={props.values?.enableEmailNotifications === "1"}
                          onChange={(e) => {
                            const isChecked = e.target.checked
                            props.setFieldValue('enableEmailNotifications', isChecked ? "1" : "0");

                          }}
                          name="enableEmailNotifications"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Mui.Grid>
                      <Mui.Grid
                        item
                        xs={12}
                        className="form-group"
                      >
                        <FieldArray
                          name="dailyReportEmail"
                          render={arrayHelpers => (
                            <div>
                              <Mui.Box style={{ gap: 10 }} className="d-flex align-items-center flex-wrap">
                                <Mui.Typography
                                  className="MuiFormLabel-root"
                                  style={{
                                    color: 'rgba(154, 161, 167, 0.5)',
                                    fontWeight: 500, fontSize: '1.6rem',
                                    letterSpacing: 0.8
                                  }}
                                >
                                  {t("SETTING.DAILY_REPORT_EMAIL")}
                                </Mui.Typography>
                                <Mui.Button
                                  size="small"
                                  disableElevation
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                  className="font-weight-normal no-color-btn"
                                >
                                  {t("SETTING.ADD_EMAIL")}
                                  <span className="ml-1 line-height-null">
                                    <IconAdd />
                                  </span>
                                </Mui.Button>
                              </Mui.Box>
                              {props.values.dailyReportEmail?.map((friend, index) => (
                                <Mui.Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  key={index}
                                  className="mt-1 mb-1"
                                >
                                  <Mui.Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={6}
                                    lg={4}>
                                    <InputField
                                      name={`dailyReportEmail.${index}`}
                                      placeholder={t("AUTH.INPUT.EMAIL")}
                                    // showStartAdornment={false}
                                    />
                                  </Mui.Grid>
                                  <Mui.Grid
                                    item
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                  >
                                    <Mui.Button
                                      size="small"
                                      disableElevation
                                      onClick={() => arrayHelpers.remove(index)}
                                      className="font-weight-normal no-color-btn"
                                    >
                                      {t("COMMON.REMOVE")}
                                      <span className="ml-1 line-height-null">
                                        <IconTrash />
                                      </span>
                                    </Mui.Button>
                                  </Mui.Grid>
                                </Mui.Grid>
                              ))
                              }
                            </div>
                          )}
                        />

                      </Mui.Grid>
                      {/* <Mui.Grid
                        item
                        xs={12}
                        className="form-group"
                      >
                        <FieldArray
                          name="monthlyReportEmail"
                          render={arrayHelpers => (
                            <div>
                              <Mui.Box style={{ gap: 10 }} className="d-flex align-items-center flex-wrap">
                                <Mui.Typography
                                  className="MuiFormLabel-root"
                                  style={{
                                    color: 'rgba(154, 161, 167, 0.5)',
                                    fontWeight: 500, fontSize: '1.6rem',
                                    letterSpacing: 0.8
                                  }}
                                >
                                  {t("SETTING.MONTHLY_REPORT_EMAIL")}
                                </Mui.Typography>
                                <Mui.Button
                                  size="small"
                                  disableElevation
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                  className="font-weight-normal no-color-btn"
                                >
                                  {t("SETTING.ADD_EMAIL")}
                                  <span className="ml-1 line-height-null">
                                    <IconAdd />
                                  </span>
                                </Mui.Button>
                              </Mui.Box>
                              {props.values.monthlyReportEmail?.map((friend, index) => (
                                <Mui.Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  key={index}
                                  className="mt-1 mb-1"
                                >
                                  <Mui.Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={6}
                                    lg={4}>
                                    <InputField
                                      name={`monthlyReportEmail.${index}`}
                                      placeholder={t("AUTH.INPUT.EMAIL")}
                                      showStartAdornment={false}
                                    />
                                  </Mui.Grid>
                                  <Mui.Grid
                                    item
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                  >
                                    <Mui.Button
                                      size="small"
                                      disableElevation
                                      onClick={() => arrayHelpers.remove(index)}
                                      className="font-weight-normal no-color-btn"
                                    >
                                      {t("COMMON.REMOVE")}
                                      <span className="ml-1 line-height-null">
                                        <IconTrash />
                                      </span>
                                    </Mui.Button>
                                  </Mui.Grid>
                                </Mui.Grid>
                              ))
                              }
                            </div>
                          )}
                        />

                      </Mui.Grid> */}
                    </Mui.Grid>
                  </>
                }


                {/* <Mui.Typography
                      component="h4"
                      variant="h4"
                      className="mb-4 font-weight-normal"
                    >
                      {t("SETTING.FORM.KIOSK_THEME_SETTING")}
                    </Mui.Typography>

                    <Mui.Grid container spacing={4} className="mb-4">
                      <Mui.Grid item xs={12} sm={4} md={3} lg={2}>
                        <Mui.Box
                          display="flex"
                          className="upload-avatar-square medium"
                          mb={Mui.isWidthDown("xs", this.props.width) ? 3 : 0}
                        >
                          {props.values?.logo?.length > 0 &&
                          props.values?.logo[0]?.url ? (
                            <Mui.Avatar
                              alt={"logo"}
                              src={props.values?.logo[0]?.url}
                              classes={{ img: "object-fit-contain" }}
                            />
                          ) : (
                            <Mui.Box
                              component="figure"
                              className="placeholder-img"
                            >
                              <IconColorsiteTitle />
                            </Mui.Box>
                          )}
                        </Mui.Box>
                      </Mui.Grid>

                      <Mui.Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className="d-flex flex-direction-column justify-content-between align-items-start"
                      >
                        <Mui.Typography
                          component="h6"
                          variant="h6"
                          className="mb-3 font-weight-normal text-color-grey"
                        >
                          <Mui.Typography
                            component="span"
                            variant="body1"
                            className="mb-1 d-block font-weight-normal text-color-default text-transform-capitalize"
                          >
                            {t("SETTING.FORM.COMPANY_LOGO")}
                          </Mui.Typography>
                          {t("SETTING.LOGO_INFO_LABEL")}
                        </Mui.Typography>

                        <Mui.Button
                          variant="contained"
                          color="primary"
                          className="btn-default"
                          onClick={this.onOpenDropzone}
                        >
                          {t("SETTING.FORM.PLACEHOLDER.COMPANY_LOGO")}
                        </Mui.Button>

                        <Mui.Popover
                          classes={{ root: "popover-dropzone" }}
                          open={this.state.openDropzone}
                          anchorEl={this.state.anchorEl}
                          onClose={() => {
                            props.setFieldValue("logo", this.state.attachment);
                            this.onCloseDropzone();
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Mui.IconButton
                            className="pos-absolute pos-top-per-3 pos-right-per-3 svg-size-xs"
                            onClick={() => { 
                              props.setFieldValue("logo", this.state.attachment); 
                              this.onCloseDropzone();
                            }}
                          >
                            <IconClose />
                          </Mui.IconButton>
                          <Mui.Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            p={3}
                          >
                            <Mui.Typography
                              component="h3"
                              variant="h3"
                              className="mb-3 font-weight-medium"
                            >
                              {t("USER.UPLOAD")}
                            </Mui.Typography>
                            <Dropzone
                              multiple={false}
                              init
                              accept={"image/*"}
                              imgArr={this.state.attachment}
                              upload={(arr) =>
                                this.setState({ attachment: arr })
                              }
                            />
                          </Mui.Box>
                        </Mui.Popover>
                      </Mui.Grid>
                    </Mui.Grid> */}

                {/* <Mui.Typography
                      component="h6"
                      variant="h6"
                      className="mb-3 pt-3 font-weight-normal text-color-grey"
                    >
                      <Mui.Typography
                        component="span"
                        variant="body1"
                        className="mb-1 d-block font-weight-normal text-color-default text-transform-capitalize"
                      >
                        {t("SETTING.FORM.THEME_COLOR")}
                      </Mui.Typography>
                      {t("SETTING.COLOR_INFO_LABEL")}
                    </Mui.Typography>

                    <Mui.Grid container spacing={4}>
                      <Mui.Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        className="form-group"
                      >
                        <InputColorpickerField
                          name="primaryColor"
                          type="text"
                          label={t("SETTING.FORM.PRIMARY_COLOR")}
                          placeholder={t(
                            "SETTING.FORM.PLACEHOLDER.PRIMARY_COLOR"
                          )}
                          callback={(hex, hue, saturation, light) => {
                            props.setFieldValue("primaryColorHue", hue);
                            props.setFieldValue(
                              "primaryColorSaturation",
                              saturation
                            );
                            props.setFieldValue("primaryColorLight", light);
                          }}
                        />
                      </Mui.Grid>
                    </Mui.Grid> */}

                {/* )} */}
                <Mui.Divider className="mt-5 mb-5" />
                <Mui.Typography
                  component="h4"
                  variant="h4"
                  className="font-weight-normal"
                >
                  {t('SETTING.TERMS_AND_CONDITIONS')}
                </Mui.Typography>
                <Mui.Grid container spacing={4}>
                  <Mui.Grid
                    item
                    xs={12}
                    className="form-group"
                  >
                    <MUIRichText
                      value={props.values.termsConditions}
                      onChange={(val) => {
                        props.setFieldValue('termsConditions', val)
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.Divider className="mt-5 mb-5" />

                <Mui.Box
                  width="100%"
                  display="flex"
                  flexDirection={
                    Mui.isWidthDown("xs", this.props.width) ? "column" : "row"
                  }
                >
                  <Mui.Button
                    className={`btn-default ${Mui.isWidthDown("xs", this.props.width) ? "mb-3" : "mr-3"
                      }`}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.props.loading}
                    disableElevation
                  >
                    {t("COMMON.BUTTON.SAVE")}
                    {this.props.loading && (
                      <Mui.CircularProgress color="secondary" size={24} />
                    )}
                  </Mui.Button>

                  <Mui.Button
                    className="font-weight-normal no-border-btn text-transform-capitalize"
                    type="button"
                    variant="text"
                    disableElevation
                    disabled={this.props.loading}
                    onClick={props.resetForm}
                  >
                    {t("COMMON.BUTTON.RESET")}
                  </Mui.Button>
                </Mui.Box>
              </Mui.Card>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = ({ setting, shared, user }) => {
  const { permission, countryList } = shared;
  const { userData } = user;
  const { settingData, loading, error, loading1, posTerminals } = setting;
  return { permission, countryList, settingData, loading, error, userData, loading1, posTerminals };
};
export default connect(mapStateToProps, {
  getSetting,
  editSetting,
  getUser,
  getCountryList,
  getPOSTerminal
})(
  withTranslation()(Mui.withWidth()(Setting))
);
