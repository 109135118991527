import * as types from '../types';


//Crockery
export const addCrockery = (crockeryData, history) => ({
    type: types.ADD_CROCKERY,
    payload: { crockeryData, history },
});

export const addCrockerySuccess = (success, message) => ({
    type: types.ADD_CROCKERY_SUCCESS,
    payload: { success, message },
});

export const addCrockeryError = (error) => ({
    type: types.ADD_CROCKERY_ERROR,
    payload: error,
});


export const deleteCrockery = (crockeryData) => ({
    type: types.DELETE_CROCKERY,
    payload: { crockeryData },
});

export const deleteCrockerySuccess = (success, message) => ({
    type: types.DELETE_CROCKERY_SUCCESS,
    payload: { success, message },
});

export const deleteCrockeryError = (error) => ({
    type: types.DELETE_CROCKERY_ERROR,
    payload: error,
});

export const editCrockery = (crockeryId, crockeryData) => ({
    type: types.EDIT_CROCKERY,
    payload: { crockeryId, crockeryData },
});

export const editCrockerySuccess = (success, message) => ({
    type: types.EDIT_CROCKERY_SUCCESS,
    payload: { success, message },
});

export const editCrockeryError = (error) => ({
    type: types.EDIT_CROCKERY_ERROR,
    payload: error,
});
