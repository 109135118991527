
export const updateSubsidyAgreements = (state, subsidyData) => {

    if (!subsidyData) return state.subsidyAgreements

    if (!state.subsidyAgreements?.find(item => item.id === subsidyData.id)) {
        return [subsidyData, ...state.subsidyAgreements]
    }

    return state.subsidyAgreements?.map((item) => {
        if (item.id === subsidyData.id) {
            return subsidyData
        }
        return item
    })
}

export const deleteSubsidyAgreement = (state, subsidyId) => {
    return state.subsidyAgreements?.filter((item) => {
        return item.id !== subsidyId
    })
}