// Canteen List Actions
export const GET_ALL_CANTEEN = 'GET_ALL_CANTEEN';
export const GET_ALL_CANTEEN_SUCCESS = 'GET_ALL_CANTEEN_SUCCESS';
export const GET_ALL_CANTEEN_ERROR = 'GET_ALL_CANTEEN_ERROR';
export const GET_CANTEEN_LIST = 'GET_CANTEEN_LIST';
export const GET_CANTEEN_LIST_SUCCESS = 'GET_CANTEEN_LIST_SUCCESS';
export const GET_CANTEEN_LIST_ERROR = 'GET_CANTEEN_LIST_ERROR';

// Canteen CRUD Actions
export const ADD_CANTEEN = 'ADD_CANTEEN';
export const ADD_CANTEEN_SUCCESS = 'ADD_CANTEEN_SUCCESS';
export const ADD_CANTEEN_ERROR = 'ADD_CANTEEN_ERROR';
export const GET_CANTEEN = 'GET_CANTEEN';
export const GET_CANTEEN_SUCCESS = 'GET_CANTEEN_SUCCESS';
export const GET_CANTEEN_ERROR = 'GET_CANTEEN_ERROR';
export const EDIT_CANTEEN = 'EDIT_CANTEEN';
export const EDIT_CANTEEN_SUCCESS = 'EDIT_CANTEEN_SUCCESS';
export const EDIT_CANTEEN_ERROR = 'EDIT_CANTEEN_ERROR';
export const DELETE_CANTEEN = 'DELETE_CANTEEN';
export const DELETE_CANTEEN_SUCCESS = 'DELETE_CANTEEN_SUCCESS';
export const DELETE_CANTEEN_ERROR = 'DELETE_CANTEEN_ERROR';
export const DELETE_MULTIPLE_CANTEEN = 'DELETE_MULTIPLE_CANTEEN';
export const DELETE_MULTIPLE_CANTEEN_SUCCESS = 'DELETE_MULTIPLE_CANTEEN_SUCCESS';
export const DELETE_MULTIPLE_CANTEEN_ERROR = 'DELETE_MULTIPLE_CANTEEN_ERROR';
export const RESET_CANTEEN = 'RESET_CANTEEN';

// Prediction Actions
export const GET_CANTEEN_PREDICTION = 'GET_CANTEEN_PREDICTION';
export const GET_CANTEEN_PREDICTION_SUCCESS = 'GET_CANTEEN_PREDICTION_SUCCESS';
export const GET_CANTEEN_PREDICTION_ERROR = 'GET_CANTEEN_PREDICTION_ERROR';

// Crockery Actions
export const ADD_CROCKERY = 'ADD_CROCKERY';
export const ADD_CROCKERY_SUCCESS = 'ADD_CROCKERY_SUCCESS';
export const ADD_CROCKERY_ERROR = 'ADD_CROCKERY_ERROR';
export const DELETE_CROCKERY = 'DELETE_CROCKERY';
export const DELETE_CROCKERY_SUCCESS = 'DELETE_CROCKERY_SUCCESS';
export const DELETE_CROCKERY_ERROR = 'DELETE_CROCKERY_ERROR';
export const EDIT_CROCKERY = 'EDIT_CROCKERY';
export const EDIT_CROCKERY_SUCCESS = 'EDIT_CROCKERY_SUCCESS';
export const EDIT_CROCKERY_ERROR = 'EDIT_CROCKERY_ERROR';

// Weighted List Actions
export const GET_WEIGHTED_LIST = 'GET_WEIGHTED_LIST';
export const GET_WEIGHTED_LIST_SUCCESS = 'GET_WEIGHTED_LIST_SUCCESS';
export const GET_WEIGHTED_LIST_ERROR = 'GET_WEIGHTED_LIST_ERROR';
export const EDIT_WEIGHTED_LIST = 'EDIT_WEIGHTED_LIST';
export const EDIT_WEIGHTED_LIST_SUCCESS = 'EDIT_WEIGHTED_LIST_SUCCESS';
export const EDIT_WEIGHTED_LIST_ERROR = 'EDIT_WEIGHTED_LIST_ERROR';

// Export Actions
export const EXPORT_CANTEEN_PRODUCTS = 'EXPORT_CANTEEN_PRODUCTS';
export const EXPORT_CANTEEN_PRODUCTS_SUCCESS = 'EXPORT_CANTEEN_PRODUCTS_SUCCESS';
export const EXPORT_CANTEEN_PRODUCTS_ERROR = 'EXPORT_CANTEEN_PRODUCTS_ERROR';

// Settings Actions
export const GET_DEFAULT_CANTEEN_SETTINGS = 'GET_DEFAULT_CANTEEN_SETTINGS';
export const GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS = 'GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS';
export const GET_DEFAULT_CANTEEN_SETTINGS_ERROR = 'GET_DEFAULT_CANTEEN_SETTINGS_ERROR';
export const GET_LIST_CANTEEN_SETTINGS = 'GET_LIST_CANTEEN_SETTINGS';
export const GET_LIST_CANTEEN_SETTINGS_SUCCESS = 'GET_LIST_CANTEEN_SETTINGS_SUCCESS';
export const GET_LIST_CANTEEN_SETTINGS_ERROR = 'GET_LIST_CANTEEN_SETTINGS_ERROR';
export const DELETE_POS_CANTEEN_SETTINGS = 'DELETE_POS_CANTEEN_SETTINGS';
export const DELETE_POS_CANTEEN_SETTINGS_SUCCESS = 'DELETE_POS_CANTEEN_SETTINGS_SUCCESS';
export const DELETE_POS_CANTEEN_SETTINGS_ERROR = 'DELETE_POS_CANTEEN_SETTINGS_ERROR';
export const SET_DEFAULT_CANTEEN_SETTINGS = 'SET_DEFAULT_CANTEEN_SETTINGS';
export const SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS = 'SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS';
export const SET_DEFAULT_CANTEEN_SETTINGS_ERROR = 'SET_DEFAULT_CANTEEN_SETTINGS_ERROR';

// Pricing Actions
export const SET_CANTEEN_LOCAL_PRICE = 'SET_CANTEEN_LOCAL_PRICE';
export const SET_CANTEEN_LOCAL_PRICE_SUCCESS = 'SET_CANTEEN_LOCAL_PRICE_SUCCESS';
export const SET_CANTEEN_LOCAL_PRICE_ERROR = 'SET_CANTEEN_LOCAL_PRICE_ERROR';
export const RESET_TO_GLOBAL_PRICES = 'RESET_TO_GLOBAL_PRICES';
export const RESET_TO_GLOBAL_PRICES_SUCCESS = 'RESET_TO_GLOBAL_PRICES_SUCCESS';
export const RESET_TO_GLOBAL_PRICES_ERROR = 'RESET_TO_GLOBAL_PRICES_ERROR';
export const UPDATE_CANTEEN_PRODUCT_PRICES = 'UPDATE_CANTEEN_PRODUCT_PRICES';
export const UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS = 'UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS';
export const UPDATE_CANTEEN_PRODUCT_PRICES_ERROR = 'UPDATE_CANTEEN_PRODUCT_PRICES_ERROR';

// Subsidy Agreement Actions
export const GET_SUBSIDY_AGREEMENTS = 'GET_SUBSIDY_AGREEMENTS';
export const GET_SUBSIDY_AGREEMENTS_SUCCESS = 'GET_SUBSIDY_AGREEMENTS_SUCCESS';
export const GET_SUBSIDY_AGREEMENTS_ERROR = 'GET_SUBSIDY_AGREEMENTS_ERROR';
export const ADD_SUBSIDY_AGREEMENT = 'ADD_SUBSIDY_AGREEMENT';
export const ADD_SUBSIDY_AGREEMENT_SUCCESS = 'ADD_SUBSIDY_AGREEMENT_SUCCESS';
export const ADD_SUBSIDY_AGREEMENT_ERROR = 'ADD_SUBSIDY_AGREEMENT_ERROR';
export const GET_SUBSIDY_AGREEMENT_DETAILS = 'GET_SUBSIDY_AGREEMENT_DETAILS';
export const GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS = 'GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS';
export const GET_SUBSIDY_AGREEMENT_DETAILS_ERROR = 'GET_SUBSIDY_AGREEMENT_DETAILS_ERROR';
export const DELETE_SUBSIDY_AGREEMENT = 'DELETE_SUBSIDY_AGREEMENT';
export const DELETE_SUBSIDY_AGREEMENT_SUCCESS = 'DELETE_SUBSIDY_AGREEMENT_SUCCESS';
export const DELETE_SUBSIDY_AGREEMENT_ERROR = 'DELETE_SUBSIDY_AGREEMENT_ERROR';