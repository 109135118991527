import Req from "../interceptors/token-interceptor";

const CanteenService = (function () {
  function _getAllCanteen() {
    return Req.get(`/api/canteens`);
  }
  function _getCanteen(id, organizationId) {
    let el = "";
    if (organizationId) {
      el += `?organizationId=${organizationId}`;
    }
    return Req.get(`/api/canteens/${id}${el}`);
  }
  function _addCanteen(data) {
    return Req.post("/api/canteens", data);
  }
  function _editCanteen(data, id) {
    return Req.put(`/api/canteens/${id}`, data);
  }
  function _deleteCanteen(id) {
    return Req.delete(`/api/canteens/${id}`);
  }
  function _deleteMultipleCanteen(ids) {
    return Req.post("/api/canteens/delete", { ids: ids });
  }
  function _getCanteenPredection(data) {
    return Req.post(`/api/ai/get-prediction-data`, data);
  }

  function _addCrockery(data) {
    return Req.post("/api/canteens/add-crockery", data);
  }

  function _editCrockery(data, id) {
    return Req.patch(`/api/canteens/edit-crockery/${id}`, data);
  }

  function _deleteCrockery(id) {
    return Req.delete(`/api/canteens/delete-crockery/${id}`);
  }
  function _getWeightedList(id) {
    return Req.get(`/api/canteens/list-products/${id}`);
  }
  function _editWeightedList(data, id) {
    return Req.post(`/api/canteens/edit-product/${id}`, data);
  }
  function _exportCanteenProducts(canteenId) {
    return Req.get(`/api/canteen-products/export/${canteenId}`, {
      responseType: "arraybuffer",
    });
  }
  function _getDefaultCanteenSettings(id) {
    return Req.get(`/api/canteens/default-pos-settings/${id}`);
  }
  function _getListCanteenSettings(id) {
    return Req.get(`/api/canteens/list-all-pos-settings/${id}`);
  }

  function _setDefaultCanteenSettings(data, id) {
    return Req.post(`/api/canteens/default-pos-settings/${id}`, data);
  }
  function _setPOSCanteenSettings(data) {
    return Req.post(`/api/pos/settings/bulk-update`, data);
  }
  function _deletePOSCanteenSettings(id, posId) {
    return Req.delete(`/api/pos/settings/${id}/${posId}`);
  }

  function _setCanteenLocalPrice(data) {
    return Req.put(`/api/canteens/update-product-prices`, data);
  }

  function _resetToGlobalPricing(data) {
    return Req.post(`/api/canteens/reset-product-prices`, data);
  }

  function _updateCanteenProductPrices(data) {
    return Req.post(`/api/canteen-products/update`, data);
  }

  function _getSubsidyAgreements(canteenId) {
    return Req.get(`/api/canteen-subsidy?canteenId=${canteenId}`);
  }

  function _addSubsidyAgreement(data) {
    if (data?.canteenSubsidyId !== "") {
      return Req.put(`/api/canteen-subsidy/${data?.canteenSubsidyId}`, data);
    }
    return Req.post(`/api/canteen-subsidy`, data);
  }

  function _getSubsidyAgreementDetails(subsidyId) {
    return Req.get(`/api/canteen-subsidy/${subsidyId}`);
  }

  function _deleteSubsidyAgreement(subsidyId) {
    return Req.delete(`/api/canteen-subsidy/${subsidyId}`);
  }


  return {
    getAllCanteen: _getAllCanteen,
    getCanteen: _getCanteen,
    addCanteen: _addCanteen,
    editCanteen: _editCanteen,
    deleteCanteen: _deleteCanteen,
    deleteMultipleCanteen: _deleteMultipleCanteen,
    getCanteenPrediction: _getCanteenPredection,
    addCrockery: _addCrockery,
    editCrockery: _editCrockery,
    deleteCrockery: _deleteCrockery,
    getWeightedList: _getWeightedList,
    editWeightedList: _editWeightedList,
    exportCanteenProducts: _exportCanteenProducts,
    getDefaultCanteenSettings: _getDefaultCanteenSettings,
    getListCanteenSettings: _getListCanteenSettings,
    setDefaultCanteenSettings: _setDefaultCanteenSettings,
    setPOSCanteenSettings: _setPOSCanteenSettings,
    deletePOSCanteenSettings: _deletePOSCanteenSettings,
    setCanteenLocalPrice: _setCanteenLocalPrice,
    resetToGlobalPricing: _resetToGlobalPricing,
    updateCanteenProductPrices: _updateCanteenProductPrices,
    getSubsidyAgreements: _getSubsidyAgreements,
    addSubsidyAgreement: _addSubsidyAgreement,
    getSubsidyAgreementDetails: _getSubsidyAgreementDetails,
    deleteSubsidyAgreement: _deleteSubsidyAgreement,
  };
})();
export default CanteenService;

