import {
    GET_CANTEEN_PREDICTION,
    GET_CANTEEN_PREDICTION_SUCCESS,
    GET_CANTEEN_PREDICTION_ERROR,
} from '../types';

export const predictionReducer = (state, action) => {
    switch (action.type) {
        case GET_CANTEEN_PREDICTION:
            return {
                ...state,
                predictionData: null,
                predictionLoading: true,
                predictionError: false,
            };
        case GET_CANTEEN_PREDICTION_SUCCESS:
            return {
                ...state,
                predictionData: action.payload,
                predictionLoading: false,
                predictionError: false,
            };
        case GET_CANTEEN_PREDICTION_ERROR:
            return {
                ...state,
                predictionData: [],
                predictionLoading: false,
                predictionError: true,
            };
        default:
            return null;
    }
}; 