import { INIT_STATE } from './initialState';
import { canteenReducer } from './canteenReducer';
import { predictionReducer } from './predictionReducer';
import { crockeryReducer } from './crockeryReducer';
import { weightedListReducer } from './weightedListReducer';
import { settingsReducer } from './settingsReducer';
import { pricingReducer } from './pricingReducer';
import { subsidyReducer } from './subsidyReducer';
import { exportReducer } from './exportReducer';

const rootReducer = (state = INIT_STATE, action) => {
    // Try each reducer in sequence
    let newState = canteenReducer(state, action);
    if (newState !== null) return newState;

    newState = predictionReducer(state, action);
    if (newState !== null) return newState;

    newState = crockeryReducer(state, action);
    if (newState !== null) return newState;

    newState = weightedListReducer(state, action);
    if (newState !== null) return newState;

    newState = exportReducer(state, action);
    if (newState !== null) return newState;

    newState = settingsReducer(state, action);
    if (newState !== null) return newState;

    newState = pricingReducer(state, action);
    if (newState !== null) return newState;

    newState = subsidyReducer(state, action);
    if (newState !== null) return newState;

    return { ...state };
};

export default rootReducer; 