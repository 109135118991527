import * as types from '../types';


export const getDefaultCanteenSettings = (canteenId) => ({
    type: types.GET_DEFAULT_CANTEEN_SETTINGS,
    payload: { canteenId },
});

export const getDefaultCanteenSettingsSuccess = (settingData) => ({
    type: types.GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
    payload: settingData,
});

export const getDefaultCanteenSettingsError = (error) => ({
    type: types.GET_DEFAULT_CANTEEN_SETTINGS_ERROR,
    payload: error,
});


export const getListCanteenSettings = (canteenId) => ({
    type: types.GET_LIST_CANTEEN_SETTINGS,
    payload: { canteenId },
});

export const getListCanteenSettingsSuccess = (settingData) => ({
    type: types.GET_LIST_CANTEEN_SETTINGS_SUCCESS,
    payload: settingData,
});

export const getListCanteenSettingsError = (error) => ({
    type: types.GET_LIST_CANTEEN_SETTINGS_ERROR,
    payload: error,
});

export const deletePOSCanteenSettings = (canteenId, posId) => ({
    type: types.DELETE_POS_CANTEEN_SETTINGS,
    payload: { canteenId, posId },
});

export const deletePOSCanteenSettingsSuccess = (success, message, posId) => ({
    type: types.DELETE_POS_CANTEEN_SETTINGS_SUCCESS,
    payload: { success, message, posId },
});

export const deletePOSCanteenSettingsError = (error) => ({
    type: types.DELETE_POS_CANTEEN_SETTINGS_ERROR,
    payload: error,
});

export const setDefaultCanteenSettings = (canteenId, settingData) => ({
    type: types.SET_DEFAULT_CANTEEN_SETTINGS,
    payload: { canteenId, settingData },
});

export const setDefaultCanteenSettingsSuccess = (success, message, settingData) => ({
    type: types.SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
    payload: { success, message, settingData },
});

export const setDefaultCanteenSettingsError = (error) => ({
    type: types.SET_DEFAULT_CANTEEN_SETTINGS_ERROR,
    payload: error,
});