import {
    GET_SUBSIDY_AGREEMENTS,
    GET_SUBSIDY_AGREEMENTS_SUCCESS,
    GET_SUBSIDY_AGREEMENTS_ERROR,
    ADD_SUBSIDY_AGREEMENT,
    ADD_SUBSIDY_AGREEMENT_SUCCESS,
    ADD_SUBSIDY_AGREEMENT_ERROR,
    GET_SUBSIDY_AGREEMENT_DETAILS,
    GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS,
    GET_SUBSIDY_AGREEMENT_DETAILS_ERROR,
    DELETE_SUBSIDY_AGREEMENT,
    DELETE_SUBSIDY_AGREEMENT_SUCCESS,
    DELETE_SUBSIDY_AGREEMENT_ERROR,
} from '../types';
import { updateSubsidyAgreements, deleteSubsidyAgreement } from '../helpers/subsidy';

export const subsidyReducer = (state, action) => {
    switch (action.type) {
        case GET_SUBSIDY_AGREEMENTS:
            return { ...state, subsidyAgreementsLoading: true, subsidyAgreementsError: false };
        case GET_SUBSIDY_AGREEMENTS_SUCCESS:
            return {
                ...state,
                subsidyAgreementsLoading: false,
                subsidyAgreements: action.payload,
                subsidyAgreementsError: false,
            };
        case GET_SUBSIDY_AGREEMENTS_ERROR:
            return {
                ...state,
                subsidyAgreementsLoading: false,
                subsidyAgreements: null,
                subsidyAgreementsError: true,
            };

        case ADD_SUBSIDY_AGREEMENT:
            return { ...state, success: false, loading2: true, error: null };
        case ADD_SUBSIDY_AGREEMENT_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                subsidyAgreements: updateSubsidyAgreements(state, action.payload.subsidyData),
                subsidyAgreementDetails: null,
                subsidyAgreementDetailsLoading: false,
                subsidyAgreementDetailsError: false,
                error: null,
            };
        case ADD_SUBSIDY_AGREEMENT_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };

        case GET_SUBSIDY_AGREEMENT_DETAILS:
            return { ...state, subsidyAgreementDetailsLoading: true, subsidyAgreementDetailsError: false };
        case GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS:
            return {
                ...state,
                subsidyAgreementDetailsLoading: false,
                subsidyAgreementDetails: action.payload,
                subsidyAgreementDetailsError: false,
            };
        case GET_SUBSIDY_AGREEMENT_DETAILS_ERROR:
            return {
                ...state,
                subsidyAgreementDetailsLoading: false,
                subsidyAgreementDetails: null,
                subsidyAgreementDetailsError: true,
            };
        case DELETE_SUBSIDY_AGREEMENT:
            return { ...state, success: false, loading2: true, error: null };
        case DELETE_SUBSIDY_AGREEMENT_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                subsidyAgreements: deleteSubsidyAgreement(state, action.payload.subsidyId),
                subsidyAgreementDetails: null,
                subsidyAgreementDetailsLoading: false,
                subsidyAgreementDetailsError: false,
                error: null,
            };
        case DELETE_SUBSIDY_AGREEMENT_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };
        default:
            return null;
    }
}; 