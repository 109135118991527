import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import CanteenService from "../../../services/canteen-service";
import {
    GET_SUBSIDY_AGREEMENTS,
    ADD_SUBSIDY_AGREEMENT,
    GET_SUBSIDY_AGREEMENT_DETAILS,
    DELETE_SUBSIDY_AGREEMENT,
} from "../types";
import {
    addSubsidyAgreementError,
    addSubsidyAgreementSuccess,
    deleteSubsidyAgreementError,
    deleteSubsidyAgreementSuccess,
    getSubsidyAgreementDetailsError,
    getSubsidyAgreementDetailsSuccess,
    getSubsidyAgreementsError,
    getSubsidyAgreementsSuccess,
} from "../action";


// Subsidy Agreements
export function* watchGetSubsidyAgreements() {
    yield takeEvery(GET_SUBSIDY_AGREEMENTS, getSubsidyAgreements);
}

const getSubsidyAgreementsAsync = async (canteenId) => {
    return CanteenService.getSubsidyAgreements(canteenId);
};

function* getSubsidyAgreements({ payload }) {
    try {
        const response = yield call(getSubsidyAgreementsAsync, payload.canteenId);
        if (response.data.success) {
            yield put(getSubsidyAgreementsSuccess(response.data.data));
        } else {
            yield put(getSubsidyAgreementsError(response.data.message));
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
        }
    } catch (error) {
        yield put(getSubsidyAgreementsError(error.response.data.message));
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
    }
}


export function* watchAddSubsidyAgreement() {
    yield takeEvery(ADD_SUBSIDY_AGREEMENT, addSubsidyAgreement);
}

const addSubsidyAgreementAsync = async (data) => {
    return CanteenService.addSubsidyAgreement(data);
};

function* addSubsidyAgreement({ payload }) {
    try {
        const response = yield call(addSubsidyAgreementAsync, payload.subsidyData);
        if (response.data.success) {
            payload?.callback?.()
            yield put(addSubsidyAgreementSuccess(response.data.success, response.data.message, response.data.data));
        } else {
            yield put(addSubsidyAgreementError(response.data.message));
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
        }
    } catch (error) {
        yield put(addSubsidyAgreementError(error.response.data.message));
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
    }
}


export function* watchGetSubsidyAgreementDetails() {
    yield takeEvery(GET_SUBSIDY_AGREEMENT_DETAILS, getSubsidyAgreementDetails);
}

const getSubsidyAgreementDetailsAsync = async (subsidyId) => {
    return CanteenService.getSubsidyAgreementDetails(subsidyId);
};

function* getSubsidyAgreementDetails({ payload }) {
    try {
        const response = yield call(getSubsidyAgreementDetailsAsync, payload.subsidyId);
        if (response.data.success) {
            payload?.callback?.(response.data.data)
            yield put(getSubsidyAgreementDetailsSuccess(response.data.data));
        } else {
            yield put(getSubsidyAgreementDetailsError(response.data.message));
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
        }
    } catch (error) {
        yield put(getSubsidyAgreementDetailsError(error.response.data.message));
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
    }
}


export function* watchDeleteSubsidyAgreement() {
    yield takeEvery(DELETE_SUBSIDY_AGREEMENT, deleteSubsidyAgreement);
}

const deleteSubsidyAgreementAsync = async (subsidyId) => {
    return CanteenService.deleteSubsidyAgreement(subsidyId);
};

function* deleteSubsidyAgreement({ payload }) {
    try {
        const response = yield call(deleteSubsidyAgreementAsync, payload.subsidyId);
        if (response.data.success) {
            payload?.callback?.()
            yield put(deleteSubsidyAgreementSuccess(response.data.success, response.data.message, payload.subsidyId));
        } else {
            yield put(deleteSubsidyAgreementError(response.data.message));
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
        }
    } catch (error) {
        yield put(deleteSubsidyAgreementError(error.response.data.message));
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
    }
}