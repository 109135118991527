import {
    GET_ALL_CANTEEN,
    GET_ALL_CANTEEN_SUCCESS,
    GET_ALL_CANTEEN_ERROR,
    GET_CANTEEN_LIST,
    GET_CANTEEN_LIST_SUCCESS,
    GET_CANTEEN_LIST_ERROR,
    ADD_CANTEEN,
    ADD_CANTEEN_SUCCESS,
    ADD_CANTEEN_ERROR,
    GET_CANTEEN,
    GET_CANTEEN_SUCCESS,
    GET_CANTEEN_ERROR,
    EDIT_CANTEEN,
    EDIT_CANTEEN_SUCCESS,
    EDIT_CANTEEN_ERROR,
    DELETE_CANTEEN,
    DELETE_CANTEEN_SUCCESS,
    DELETE_CANTEEN_ERROR,
    DELETE_MULTIPLE_CANTEEN,
    DELETE_MULTIPLE_CANTEEN_SUCCESS,
    DELETE_MULTIPLE_CANTEEN_ERROR,
    RESET_CANTEEN,
} from '../types';

export const canteenReducer = (state, action) => {
    switch (action.type) {
        case GET_ALL_CANTEEN:
            return {
                ...state,
                error: null,
            };
        case GET_ALL_CANTEEN_SUCCESS:
            return {
                ...state,
                canteens: action.payload,
                error: null,
            };
        case GET_ALL_CANTEEN_ERROR:
            return {
                ...state,
                canteens: null,
                error: action.payload,
            };
        case GET_CANTEEN_LIST:
            return {
                ...state,
                loading: true,
                canteenId: null,
                canteenData: null,
                canteenIds: null,
                error: null,
            };
        case GET_CANTEEN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                canteenList: action.payload,
                error: null,
            };
        case GET_CANTEEN_LIST_ERROR:
            return {
                ...state,
                loading: false,
                canteenList: null,
                error: action.payload,
            };
        case ADD_CANTEEN:
            return { ...state, loading: true, error: null };
        case ADD_CANTEEN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case ADD_CANTEEN_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case GET_CANTEEN:
            return { ...state, loading: true, error: null };
        case GET_CANTEEN_SUCCESS:
            return {
                ...state,
                loading: false,
                canteenData: action.payload,
                error: null,
            };
        case GET_CANTEEN_ERROR:
            return {
                ...state,
                loading: false,
                canteenData: null,
                error: action.payload,
            };
        case EDIT_CANTEEN:
            return { ...state, loading: true, error: null };
        case EDIT_CANTEEN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case EDIT_CANTEEN_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case DELETE_CANTEEN:
            return { ...state, loading1: true, error: null };
        case DELETE_CANTEEN_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case DELETE_CANTEEN_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case DELETE_MULTIPLE_CANTEEN:
            return { ...state, loading1: true, error: null };
        case DELETE_MULTIPLE_CANTEEN_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case DELETE_MULTIPLE_CANTEEN_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case RESET_CANTEEN:
            return {
                ...state,
                loading: false,
                loading1: false,
                success: false,
                message: null,
                error: null,
            };

        default:
            return null;
    }
}; 