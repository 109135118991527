// import { resetToGlobalPrices, updateCanteenProductPrices, updateCanteenProducts } from "./helpers/pricing";
// import { deleteFromListCanteenSettings, updateListCanteenSettings } from "./helpers/settings";
// import { deleteSubsidyAgreement, updateSubsidyAgreements } from "./helpers/subsidy";
// import {
//   GET_ALL_CANTEEN,
//   GET_ALL_CANTEEN_SUCCESS,
//   GET_ALL_CANTEEN_ERROR,
//   GET_CANTEEN_LIST,
//   GET_CANTEEN_LIST_SUCCESS,
//   GET_CANTEEN_LIST_ERROR,
//   ADD_CANTEEN,
//   ADD_CANTEEN_SUCCESS,
//   ADD_CANTEEN_ERROR,
//   GET_CANTEEN,
//   GET_CANTEEN_SUCCESS,
//   GET_CANTEEN_ERROR,
//   EDIT_CANTEEN,
//   EDIT_CANTEEN_SUCCESS,
//   EDIT_CANTEEN_ERROR,
//   DELETE_CANTEEN,
//   DELETE_CANTEEN_SUCCESS,
//   DELETE_CANTEEN_ERROR,
//   DELETE_MULTIPLE_CANTEEN,
//   DELETE_MULTIPLE_CANTEEN_SUCCESS,
//   DELETE_MULTIPLE_CANTEEN_ERROR,
//   RESET_CANTEEN,
//   GET_CANTEEN_PREDICTION,
//   GET_CANTEEN_PREDICTION_SUCCESS,
//   GET_CANTEEN_PREDICTION_ERROR,
//   ADD_CROCKERY,
//   ADD_CROCKERY_SUCCESS,
//   ADD_CROCKERY_ERROR,
//   DELETE_CROCKERY,
//   DELETE_CROCKERY_SUCCESS,
//   DELETE_CROCKERY_ERROR,
//   EDIT_CROCKERY,
//   EDIT_CROCKERY_SUCCESS,
//   EDIT_CROCKERY_ERROR,
//   GET_WEIGHTED_LIST,
//   GET_WEIGHTED_LIST_SUCCESS,
//   GET_WEIGHTED_LIST_ERROR,
//   EDIT_WEIGHTED_LIST,
//   EDIT_WEIGHTED_LIST_ERROR,
//   EDIT_WEIGHTED_LIST_SUCCESS,
//   EXPORT_CANTEEN_PRODUCTS,
//   EXPORT_CANTEEN_PRODUCTS_SUCCESS,
//   EXPORT_CANTEEN_PRODUCTS_ERROR,
//   GET_DEFAULT_CANTEEN_SETTINGS,
//   GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
//   GET_DEFAULT_CANTEEN_SETTINGS_ERROR,
//   GET_LIST_CANTEEN_SETTINGS,
//   GET_LIST_CANTEEN_SETTINGS_SUCCESS,
//   GET_LIST_CANTEEN_SETTINGS_ERROR,
//   DELETE_POS_CANTEEN_SETTINGS,
//   DELETE_POS_CANTEEN_SETTINGS_SUCCESS,
//   DELETE_POS_CANTEEN_SETTINGS_ERROR,
//   SET_DEFAULT_CANTEEN_SETTINGS,
//   SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
//   SET_DEFAULT_CANTEEN_SETTINGS_ERROR,
//   SET_CANTEEN_LOCAL_PRICE,
//   SET_CANTEEN_LOCAL_PRICE_SUCCESS,
//   SET_CANTEEN_LOCAL_PRICE_ERROR,
//   RESET_TO_GLOBAL_PRICES,
//   RESET_TO_GLOBAL_PRICES_SUCCESS,
//   RESET_TO_GLOBAL_PRICES_ERROR,
//   UPDATE_CANTEEN_PRODUCT_PRICES,
//   UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS,
//   UPDATE_CANTEEN_PRODUCT_PRICES_ERROR,
//   GET_SUBSIDY_AGREEMENTS,
//   GET_SUBSIDY_AGREEMENTS_SUCCESS,
//   GET_SUBSIDY_AGREEMENTS_ERROR,
//   ADD_SUBSIDY_AGREEMENT,
//   ADD_SUBSIDY_AGREEMENT_SUCCESS,
//   ADD_SUBSIDY_AGREEMENT_ERROR,
//   GET_SUBSIDY_AGREEMENT_DETAILS,
//   GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS,
//   GET_SUBSIDY_AGREEMENT_DETAILS_ERROR,
//   DELETE_SUBSIDY_AGREEMENT,
//   DELETE_SUBSIDY_AGREEMENT_SUCCESS,
//   DELETE_SUBSIDY_AGREEMENT_ERROR,
// } from "./types";

// const INIT_STATE = {
//   dbParam: null,
//   canteens: null,
//   canteenList: null,
//   canteenData: null,
//   predictionData: null,
//   predictionLoading: false,
//   predictionError: false,
//   canteenId: null,
//   organizationId: null,
//   canteenIds: null,
//   success: false,
//   message: null,
//   loading: false,
//   loading1: false,
//   loading2: false,
//   error: null,

//   crockeryLoading: false,

//   weightedListLoading: false,
//   weightedListLoading1: false,
//   weightedList: null,

//   defaultCanteenSettings: null,
//   listCanteenSettings: null,


//   subsidyAgreements: null,
//   subsidyAgreementsLoading: false,
//   subsidyAgreementsError: false,

//   subsidyAgreementDetails: null,
//   subsidyAgreementDetailsLoading: false,
//   subsidyAgreementDetailsError: false,
// };

// const canteenReducer = (state = INIT_STATE, action) => {
//   switch (action.type) {
//     // case GET_ALL_CANTEEN:
//     //   return {
//     //     ...state,
//     //     error: null,
//     //   };
//     // case GET_ALL_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     canteens: action.payload,
//     //     error: null,
//     //   };
//     // case GET_ALL_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     canteens: null,
//     //     error: action.payload,
//     //   };
//     // case GET_CANTEEN_LIST:
//     //   return {
//     //     ...state,
//     //     loading: true,
//     //     canteenId: null,
//     //     canteenData: null,
//     //     canteenIds: null,
//     //     error: null,
//     //   };
//     // case GET_CANTEEN_LIST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     canteenList: action.payload,
//     //     error: null,
//     //   };
//     // case GET_CANTEEN_LIST_ERROR:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     canteenList: null,
//     //     error: action.payload,
//     //   };
//     // case ADD_CANTEEN:
//     //   return { ...state, loading: true, error: null };
//     // case ADD_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case ADD_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case GET_CANTEEN:
//     //   return { ...state, loading: true, error: null };
//     // case GET_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     canteenData: action.payload,
//     //     error: null,
//     //   };
//     // case GET_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     canteenData: null,
//     //     error: action.payload,
//     //   };
//     // case EDIT_CANTEEN:
//     //   return { ...state, loading: true, error: null };
//     // case EDIT_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case EDIT_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case DELETE_CANTEEN:
//     //   return { ...state, loading1: true, error: null };
//     // case DELETE_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case DELETE_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case DELETE_MULTIPLE_CANTEEN:
//     //   return { ...state, loading1: true, error: null };
//     // case DELETE_MULTIPLE_CANTEEN_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case DELETE_MULTIPLE_CANTEEN_ERROR:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case RESET_CANTEEN:
//     //   return {
//     //     ...state,
//     //     loading: false,
//     //     loading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: null,
//     //   };
//     // case GET_CANTEEN_PREDICTION:
//     //   return {
//     //     ...state,
//     //     predictionData: null,
//     //     predictionLoading: true,
//     //     predictionError: false,
//     //   };
//     // case GET_CANTEEN_PREDICTION_SUCCESS:
//     //   return {
//     //     ...state,
//     //     predictionData: action.payload,
//     //     predictionLoading: false,
//     //     predictionError: false,
//     //   };
//     // case GET_CANTEEN_PREDICTION_ERROR:
//     //   return {
//     //     ...state,
//     //     predictionData: [],
//     //     predictionLoading: false,
//     //     predictionError: true,
//     //   };

//     // case ADD_CROCKERY:
//     //   return { ...state, success: false, crockeryLoading: true, error: null };
//     // case ADD_CROCKERY_SUCCESS:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case ADD_CROCKERY_ERROR:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };


//     // case DELETE_CROCKERY:
//     //   return { ...state, success: false, crockeryLoading: true, error: null };
//     // case DELETE_CROCKERY_SUCCESS:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case DELETE_CROCKERY_ERROR:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };


//     // case EDIT_CROCKERY:
//     //   return { ...state, success: false, crockeryLoading: true, error: null };
//     // case EDIT_CROCKERY_SUCCESS:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case EDIT_CROCKERY_ERROR:
//     //   return {
//     //     ...state,
//     //     crockeryLoading: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };


//     // case GET_WEIGHTED_LIST:
//     //   return {
//     //     ...state,
//     //     weightedList: null,
//     //     weightedListLoading: true,
//     //     success: false,
//     //     error: null
//     //   };
//     // case GET_WEIGHTED_LIST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     weightedList: action.payload,
//     //     weightedListLoading: false,
//     //     success: true,
//     //     error: null
//     //   };
//     // case GET_WEIGHTED_LIST_ERROR:
//     //   return {
//     //     ...state,
//     //     weightedList: null,
//     //     weightedListLoading: false,
//     //     success: false,
//     //     error: action.payload
//     //   };


//     // case EDIT_WEIGHTED_LIST:
//     //   return { ...state, success: false, weightedListLoading1: true, error: null };
//     // case EDIT_WEIGHTED_LIST_SUCCESS:
//     //   return {
//     //     ...state,
//     //     weightedListLoading1: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case EDIT_WEIGHTED_LIST_ERROR:
//     //   return {
//     //     ...state,
//     //     weightedListLoading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };

//     // case EXPORT_CANTEEN_PRODUCTS:
//     //   return { ...state, loading1: true, success: false, error: null };
//     // case EXPORT_CANTEEN_PRODUCTS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     error: null,
//     //   };
//     // case EXPORT_CANTEEN_PRODUCTS_ERROR:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case GET_DEFAULT_CANTEEN_SETTINGS:
//     //   return { ...state, loading: true, error: null };
//     // case GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     defaultCanteenSettings: action.payload,
//     //     error: null,
//     //     loading: false,
//     //   };
//     // case GET_DEFAULT_CANTEEN_SETTINGS_ERROR:
//     //   return {
//     //     ...state,
//     //     defaultCanteenSettings: null,
//     //     loading: false,
//     //     error: action.payload,
//     //   };
//     // case GET_LIST_CANTEEN_SETTINGS:
//     //   return { ...state, error: null };
//     // case GET_LIST_CANTEEN_SETTINGS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     listCanteenSettings: action.payload,
//     //     error: null,
//     //   };
//     // case GET_LIST_CANTEEN_SETTINGS_ERROR:
//     //   return {
//     //     ...state,
//     //     listCanteenSettings: null,
//     //     error: action.payload,
//     //   };
//     // case DELETE_POS_CANTEEN_SETTINGS:
//     //   return { ...state, success: false, loading1: true, error: null };
//     // case DELETE_POS_CANTEEN_SETTINGS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     listCanteenSettings: deleteFromListCanteenSettings(state, action.payload.posId),
//     //     error: null,
//     //   };
//     // case DELETE_POS_CANTEEN_SETTINGS_ERROR:
//     //   return {
//     //     ...state,
//     //     loading1: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case SET_DEFAULT_CANTEEN_SETTINGS:
//     //   return { ...state, loading2: true, error: null };
//     // case SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     defaultCanteenSettings: action.payload.settingData?.posId === null ? { ...state.defaultCanteenSettings, ...action.payload.settingData } : state.defaultCanteenSettings,
//     //     listCanteenSettings: updateListCanteenSettings(state, action.payload.settingData),
//     //     error: null,
//     //   };
//     // case SET_DEFAULT_CANTEEN_SETTINGS_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case SET_CANTEEN_LOCAL_PRICE:
//     //   return { ...state, loading2: true, error: null };
//     // case SET_CANTEEN_LOCAL_PRICE_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     canteenData: updateCanteenProductPrices(state, action.payload.priceData),
//     //     error: null,
//     //   };
//     // case SET_CANTEEN_LOCAL_PRICE_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case RESET_TO_GLOBAL_PRICES:
//     //   return { ...state, loading2: true, error: null };
//     // case RESET_TO_GLOBAL_PRICES_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     canteenData: resetToGlobalPrices(state, action.payload.resetData),
//     //     error: null,
//     //   };
//     // case RESET_TO_GLOBAL_PRICES_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     // case UPDATE_CANTEEN_PRODUCT_PRICES:
//     //   return { ...state, loading2: true, error: null };
//     // case UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     canteenData: updateCanteenProducts(state, action.payload.canteenData),
//     //     error: null,
//     //   };
//     // case UPDATE_CANTEEN_PRODUCT_PRICES_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };

//     // case GET_SUBSIDY_AGREEMENTS:
//     //   return { ...state, subsidyAgreementsLoading: true, subsidyAgreementsError: false };
//     // case GET_SUBSIDY_AGREEMENTS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     subsidyAgreementsLoading: false,
//     //     subsidyAgreements: action.payload,
//     //     subsidyAgreementsError: false,
//     //   };
//     // case GET_SUBSIDY_AGREEMENTS_ERROR:
//     //   return {
//     //     ...state,
//     //     subsidyAgreementsLoading: false,
//     //     subsidyAgreements: null,
//     //     subsidyAgreementsError: true,
//     //   };

//     // case ADD_SUBSIDY_AGREEMENT:
//     //   return { ...state, success: false, loading2: true, error: null };
//     // case ADD_SUBSIDY_AGREEMENT_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     subsidyAgreements: updateSubsidyAgreements(state, action.payload.subsidyData),
//     //     subsidyAgreementDetails: null,
//     //     subsidyAgreementDetailsLoading: false,
//     //     subsidyAgreementDetailsError: false,
//     //     error: null,
//     //   };
//     // case ADD_SUBSIDY_AGREEMENT_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };

//     // case GET_SUBSIDY_AGREEMENT_DETAILS:
//     //   return { ...state, subsidyAgreementDetailsLoading: true, subsidyAgreementDetailsError: false };
//     // case GET_SUBSIDY_AGREEMENT_DETAILS_SUCCESS:
//     //   return {
//     //     ...state,
//     //     subsidyAgreementDetailsLoading: false,
//     //     subsidyAgreementDetails: action.payload,
//     //     subsidyAgreementDetailsError: false,
//     //   };
//     // case GET_SUBSIDY_AGREEMENT_DETAILS_ERROR:
//     //   return {
//     //     ...state,
//     //     subsidyAgreementDetailsLoading: false,
//     //     subsidyAgreementDetails: null,
//     //     subsidyAgreementDetailsError: true,
//     //   };
//     // case DELETE_SUBSIDY_AGREEMENT:
//     //   return { ...state, success: false, loading2: true, error: null };
//     // case DELETE_SUBSIDY_AGREEMENT_SUCCESS:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: action.payload.success,
//     //     message: action.payload.message,
//     //     subsidyAgreements: deleteSubsidyAgreement(state, action.payload.subsidyId),
//     //     subsidyAgreementDetails: null,
//     //     subsidyAgreementDetailsLoading: false,
//     //     subsidyAgreementDetailsError: false,
//     //     error: null,
//     //   };
//     // case DELETE_SUBSIDY_AGREEMENT_ERROR:
//     //   return {
//     //     ...state,
//     //     loading2: false,
//     //     success: false,
//     //     message: null,
//     //     error: action.payload,
//     //   };
//     default:
//       return { ...state };
//   }
// };
// export default canteenReducer;

export { default } from './reducers'


