import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import CanteenService from "../../../services/canteen-service";
import * as types from "../types";
import {
    getCanteenPredictionError,
    getCanteenPredictionSuccess,
} from "../action";


export function* watchGetCanteenPrediction() {
    yield takeEvery(types.GET_CANTEEN_PREDICTION, getCanteenPrediction);
}

const getCanteenPredictionAsync = async (data) => {
    return CanteenService.getCanteenPrediction(data);
};

function* getCanteenPrediction({ payload }) {
    try {
        const response = yield call(getCanteenPredictionAsync, payload);
        if (response.data.success) {
            yield put(getCanteenPredictionSuccess({ ...response.data.data, canteenId: payload.izyCanteenId }));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getCanteenPredictionError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getCanteenPredictionError(error.response.data.message));
    }
}
