import * as types from '../types';

export const exportCanteenProducts = (canteenId, canteenName) => ({
    type: types.EXPORT_CANTEEN_PRODUCTS,
    payload: { canteenId, canteenName },
});

export const exportCanteenProductsSuccess = (success, message) => ({
    type: types.EXPORT_CANTEEN_PRODUCTS_SUCCESS,
    payload: { success, message },
});

export const exportCanteenProductsError = (error) => ({
    type: types.EXPORT_CANTEEN_PRODUCTS_ERROR,
    payload: error,
});