import * as types from '../types';


//Crockery-product association
export const getWeightedList = (canteenId) => ({
    type: types.GET_WEIGHTED_LIST,
    payload: { canteenId },
});

export const getWeightedListSuccess = (canteenData) => ({
    type: types.GET_WEIGHTED_LIST_SUCCESS,
    payload: canteenData,
});

export const getWeightedListError = (error) => ({
    type: types.GET_WEIGHTED_LIST_ERROR,
    payload: error,
});

export const editWeightedList = (productId, crockeryData) => ({
    type: types.EDIT_WEIGHTED_LIST,
    payload: { productId, crockeryData },
});

export const editWeightedListSuccess = (success, message) => ({
    type: types.EDIT_WEIGHTED_LIST_SUCCESS,
    payload: { success, message },
});

export const editWeightedListError = (error) => ({
    type: types.EDIT_WEIGHTED_LIST_ERROR,
    payload: error,
});
