import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import { parseMessage } from "../../../helpers/util";
import CanteenService from "../../../services/canteen-service";
import {
    GET_DEFAULT_CANTEEN_SETTINGS,
    GET_LIST_CANTEEN_SETTINGS,
    DELETE_POS_CANTEEN_SETTINGS,
    SET_DEFAULT_CANTEEN_SETTINGS,
} from "../types";
import {
    deletePOSCanteenSettingsError,
    deletePOSCanteenSettingsSuccess,
    getDefaultCanteenSettingsError,
    getDefaultCanteenSettingsSuccess,
    getListCanteenSettingsError,
    getListCanteenSettingsSuccess,
    setDefaultCanteenSettingsError,
    setDefaultCanteenSettingsSuccess,
} from "../action";


export function* watchGetDefaultCanteenSettings() {
    yield takeEvery(GET_DEFAULT_CANTEEN_SETTINGS, getDefaultCanteenSettings);
}

const getDefaultCanteenSettingsAsync = async (id) => {
    return CanteenService.getDefaultCanteenSettings(id);
};

function* getDefaultCanteenSettings({ payload }) {
    try {
        const response = yield call(getDefaultCanteenSettingsAsync, payload.canteenId);
        if (response.data.success) {
            yield put(getDefaultCanteenSettingsSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getDefaultCanteenSettingsError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getDefaultCanteenSettingsError(error.response.data.message));
    }
}


export function* watchGetListCanteenSettings() {
    yield takeEvery(GET_LIST_CANTEEN_SETTINGS, getListCanteenSettingsAc);
}

const getListCanteenSettingsAsync = async (id) => {
    return CanteenService.getListCanteenSettings(id);
};

function* getListCanteenSettingsAc({ payload }) {
    try {
        const response = yield call(getListCanteenSettingsAsync, payload.canteenId);
        if (response.data.success) {
            yield put(getListCanteenSettingsSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getListCanteenSettingsError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getListCanteenSettingsError(error.response.data.message));
    }
}


export function* watchDeletePOSCanteenSettings() {
    yield takeEvery(DELETE_POS_CANTEEN_SETTINGS, deletePOSCanteenSettings);
}

const deletePOSCanteenSettingsAsync = async (id, posId) => {
    return CanteenService.deletePOSCanteenSettings(id, posId);
};

function* deletePOSCanteenSettings({ payload }) {
    try {
        const response = yield call(deletePOSCanteenSettingsAsync, payload.canteenId, payload.posId);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                deletePOSCanteenSettingsSuccess(response.data.success, response.data.message, payload.posId)
            );
        } else {
            yield put(deletePOSCanteenSettingsError(response.data.message));
        }
    } catch (error) {
        yield put(deletePOSCanteenSettingsError(error.response.data.message));
    }
}



export function* watchSetDefaultCanteenSettings() {
    yield takeEvery(SET_DEFAULT_CANTEEN_SETTINGS, setDefaultCanteenSettings);
}

const setDefaultCanteenSettingsAsync = async (data, id) => {
    if (data?.posId !== null) {
        const val = {
            canteenId: data.canteenId,
            posSettings: [data]
        }
        return CanteenService.setPOSCanteenSettings(val)
    }
    return CanteenService.setDefaultCanteenSettings(data, id);
};

function* setDefaultCanteenSettings({ payload }) {
    try {
        const response = yield call(
            setDefaultCanteenSettingsAsync,
            payload.settingData,
            payload.canteenId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                setDefaultCanteenSettingsSuccess(response.data.success, response.data.message, payload.settingData)
            );
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(setDefaultCanteenSettingsError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(setDefaultCanteenSettingsError(error.response.data.message));
    }
}
