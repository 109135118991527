import {
    GET_DEFAULT_CANTEEN_SETTINGS,
    GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
    GET_DEFAULT_CANTEEN_SETTINGS_ERROR,
    GET_LIST_CANTEEN_SETTINGS,
    GET_LIST_CANTEEN_SETTINGS_SUCCESS,
    GET_LIST_CANTEEN_SETTINGS_ERROR,
    DELETE_POS_CANTEEN_SETTINGS,
    DELETE_POS_CANTEEN_SETTINGS_SUCCESS,
    DELETE_POS_CANTEEN_SETTINGS_ERROR,
    SET_DEFAULT_CANTEEN_SETTINGS,
    SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS,
    SET_DEFAULT_CANTEEN_SETTINGS_ERROR,
} from '../types';
import { updateListCanteenSettings, deleteFromListCanteenSettings } from '../helpers/settings';

export const settingsReducer = (state, action) => {
    switch (action.type) {
        case GET_DEFAULT_CANTEEN_SETTINGS:
            return { ...state, loading: true, error: null };
        case GET_DEFAULT_CANTEEN_SETTINGS_SUCCESS:
            return {
                ...state,
                defaultCanteenSettings: action.payload,
                error: null,
                loading: false,
            };
        case GET_DEFAULT_CANTEEN_SETTINGS_ERROR:
            return {
                ...state,
                defaultCanteenSettings: null,
                loading: false,
                error: action.payload,
            };
        case GET_LIST_CANTEEN_SETTINGS:
            return { ...state, error: null };
        case GET_LIST_CANTEEN_SETTINGS_SUCCESS:
            return {
                ...state,
                listCanteenSettings: action.payload,
                error: null,
            };
        case GET_LIST_CANTEEN_SETTINGS_ERROR:
            return {
                ...state,
                listCanteenSettings: null,
                error: action.payload,
            };
        case DELETE_POS_CANTEEN_SETTINGS:
            return { ...state, success: false, loading1: true, error: null };
        case DELETE_POS_CANTEEN_SETTINGS_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                listCanteenSettings: deleteFromListCanteenSettings(state, action.payload.posId),
                error: null,
            };
        case DELETE_POS_CANTEEN_SETTINGS_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case SET_DEFAULT_CANTEEN_SETTINGS:
            return { ...state, loading2: true, error: null };
        case SET_DEFAULT_CANTEEN_SETTINGS_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                defaultCanteenSettings: action.payload.settingData?.posId === null ? { ...state.defaultCanteenSettings, ...action.payload.settingData } : state.defaultCanteenSettings,
                listCanteenSettings: updateListCanteenSettings(state, action.payload.settingData),
                error: null,
            };
        case SET_DEFAULT_CANTEEN_SETTINGS_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };
        default:
            return null;
    }
}; 