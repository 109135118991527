export const INIT_STATE = {
    dbParam: null,
    canteens: null,
    canteenList: null,
    canteenData: null,
    predictionData: null,
    predictionLoading: false,
    predictionError: false,
    canteenId: null,
    organizationId: null,
    canteenIds: null,
    success: false,
    message: null,
    loading: false,
    loading1: false,
    loading2: false,
    error: null,
    crockeryLoading: false,
    weightedListLoading: false,
    weightedListLoading1: false,
    weightedList: null,
    defaultCanteenSettings: null,
    listCanteenSettings: null,
    subsidyAgreements: null,
    subsidyAgreementsLoading: false,
    subsidyAgreementsError: false,
    subsidyAgreementDetails: null,
    subsidyAgreementDetailsLoading: false,
    subsidyAgreementDetailsError: false,
}; 