// import saveAs from "file-saver";
// import moment from "moment";
// import { toast } from "react-toastify";
// import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import ToastElement from "../../components/toast";
// import { parseMessage } from "../../helpers/util";
// import CanteenService from "../../services/canteen-service";
// import TableDataService from "../../services/table-data-service";
// import {
//   ADD_CANTEEN,
//   DELETE_CANTEEN,
//   DELETE_MULTIPLE_CANTEEN,
//   EDIT_CANTEEN,
//   GET_ALL_CANTEEN,
//   GET_CANTEEN,
//   GET_CANTEEN_LIST,
//   GET_CANTEEN_PREDICTION,
//   ADD_CROCKERY,
//   EDIT_CROCKERY,
//   DELETE_CROCKERY,
//   ADD_SUBSIDY_AGREEMENT,
//   GET_SUBSIDY_AGREEMENT_DETAILS,
//   GET_SUBSIDY_AGREEMENTS,
//   DELETE_SUBSIDY_AGREEMENT,
//   DELETE_POS_CANTEEN_SETTINGS,
//   SET_DEFAULT_CANTEEN_SETTINGS,
//   GET_LIST_CANTEEN_SETTINGS,
//   GET_DEFAULT_CANTEEN_SETTINGS,
//   UPDATE_CANTEEN_PRODUCT_PRICES,
//   RESET_TO_GLOBAL_PRICES,
//   SET_CANTEEN_LOCAL_PRICE,
//   EXPORT_CANTEEN_PRODUCTS,
//   GET_WEIGHTED_LIST,
//   EDIT_WEIGHTED_LIST
// } from "./types";
// import {
//   addCanteenError,
//   addCanteenSuccess,
//   addCrockeryError,
//   addCrockerySuccess,
//   addSubsidyAgreementError,
//   addSubsidyAgreementSuccess,
//   deleteCanteenError,
//   deleteCanteenSuccess,
//   deleteCrockeryError,
//   deleteCrockerySuccess,
//   deleteMultipleCanteenError,
//   deleteMultipleCanteenSuccess,
//   deletePOSCanteenSettingsError,
//   deletePOSCanteenSettingsSuccess,
//   deleteSubsidyAgreementError,
//   deleteSubsidyAgreementSuccess,
//   editCanteenError,
//   editCanteenSuccess,
//   editCrockeryError,
//   editCrockerySuccess,
//   editWeightedListError,
//   editWeightedListSuccess,
//   exportCanteenProductsError,
//   exportCanteenProductsSuccess,
//   getAllCanteenError,
//   getAllCanteenSuccess,
//   getCanteen as getCanteenData,
//   getCanteenError,
//   getCanteenList,
//   getCanteenListError,
//   getCanteenListSuccess,
//   getCanteenPredictionError,
//   getCanteenPredictionSuccess,
//   getCanteenSuccess,
//   getDefaultCanteenSettingsError,
//   getDefaultCanteenSettingsSuccess,
//   getListCanteenSettingsError,
//   getListCanteenSettingsSuccess,
//   getSubsidyAgreementDetailsError,
//   getSubsidyAgreementDetailsSuccess,
//   getSubsidyAgreementsError,
//   getSubsidyAgreementsSuccess,
//   getWeightedList as getWeightedListData,
//   getWeightedListError,
//   getWeightedListSuccess,
//   resetToGlobalPricingError,
//   resetToGlobalPricingSuccess,
//   setCanteenLocalPriceError,
//   setCanteenLocalPriceSuccess,
//   setDefaultCanteenSettingsError,
//   setDefaultCanteenSettingsSuccess,
//   updateCanteenProductPricesError,
//   updateCanteenProductPricesSuccess
// } from "./action";

// export function* watchGetAllCanteen() {
//   yield takeEvery(GET_ALL_CANTEEN, getAllCanteen);
// }

// const getAllCanteenAsync = async () => {
//   return CanteenService.getAllCanteen();
// };

// function* getAllCanteen() {
//   try {
//     const response = yield call(getAllCanteenAsync);
//     if (response.data.success) {
//       yield put(getAllCanteenSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getAllCanteenError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getAllCanteenError(error.response.data.message));
//   }
// }

// export function* watchGetCanteenList() {
//   yield takeEvery(GET_CANTEEN_LIST, getCanteenListAc);
// }

// const getCanteenListAsync = async (dbParam) => {
//   return TableDataService.getAllData(
//     "canteens",
//     dbParam?.orgId || "",
//     dbParam?.search || "",
//     dbParam?.searchFields || "",
//     dbParam?.sortOrder || "",
//     dbParam?.page || 1,
//     dbParam?.pageSize || 10,
//     dbParam?.activeCol || "",
//     dbParam?.statusId || null,
//     dbParam?.buildingId || null
//   );
// };

// function* getCanteenListAc({ payload }) {
//   try {
//     const response = yield call(getCanteenListAsync, payload.dbParam);
//     if (response.data.success) {
//       yield put(getCanteenListSuccess(response.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getCanteenListError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getCanteenListError(error.response.data.message));
//   }
// }

// export function* watchAddCanteen() {
//   yield takeEvery(ADD_CANTEEN, addCanteen);
// }

// const addCanteenAsync = async (data) => {
//   return CanteenService.addCanteen(data);
// };

// function* addCanteen({ payload }) {
//   const { history, location } = payload;
//   try {
//     const response = yield call(addCanteenAsync, payload.canteenData);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         addCanteenSuccess(response.data.success, response.data.message)
//       );
//       history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(addCanteenError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(addCanteenError(error.response.data.message));
//   }
// }

// export function* watchGetCanteen() {
//   yield takeEvery(GET_CANTEEN, getCanteen);
// }

// const getCanteenAsync = async (id, organizationId) => {
//   return CanteenService.getCanteen(id, organizationId);
// };

// function* getCanteen({ payload }) {
//   try {
//     const response = yield call(
//       getCanteenAsync,
//       payload.canteenId,
//       payload.organizationId
//     );
//     if (response.data.success) {
//       yield put(getCanteenSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getCanteenError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getCanteenError(error.response.data.message));
//   }
// }

// export function* watchEditCanteen() {
//   yield takeEvery(EDIT_CANTEEN, editCanteen);
// }

// const editCanteenAsync = async (data, id) => {
//   return CanteenService.editCanteen(data, id);
// };

// function* editCanteen({ payload }) {
//   const { history, location } = payload;
//   try {
//     const response = yield call(
//       editCanteenAsync,
//       payload.canteenData,
//       payload.canteenId
//     );
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         editCanteenSuccess(response.data.success, response.data.message)
//       );
//       history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(editCanteenError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(editCanteenError(error.response.data.message));
//   }
// }

// export function* watchDeleteCanteen() {
//   yield takeEvery(DELETE_CANTEEN, deleteCanteen);
// }

// const deleteCanteenAsync = async (id) => {
//   return CanteenService.deleteCanteen(id);
// };

// function* deleteCanteen({ payload }) {
//   try {
//     const response = yield call(deleteCanteenAsync, payload.canteenId);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         deleteCanteenSuccess(response.data.success, response.data.message)
//       );
//       // Fetch updated canteen list
//       yield put(getCanteenList({}));
//     } else {
//       yield put(deleteCanteenError(response.data.message));
//     }
//   } catch (error) {
//     yield put(deleteCanteenError(error.response.data.message));
//   }
// }

// export function* watchDeleteMultipleCanteen() {
//   yield takeEvery(DELETE_MULTIPLE_CANTEEN, deleteMultipleCanteen);
// }

// const deleteMultipleCanteenAsync = async (ids) => {
//   return CanteenService.deleteMultipleCanteen(ids);
// };

// function* deleteMultipleCanteen({ payload }) {
//   try {
//     const response = yield call(deleteMultipleCanteenAsync, payload.canteenIds);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         deleteMultipleCanteenSuccess(
//           response.data.success,
//           response.data.message
//         )
//       );
//       // Fetch updated canteen list
//       yield put(getCanteenList({}));
//     } else {
//       yield put(deleteMultipleCanteenError(response.data.message));
//     }
//   } catch (error) {
//     yield put(deleteMultipleCanteenError(error.response.data.message));
//   }
// }

// export function* watchGetCanteenPrediction() {
//   yield takeEvery(GET_CANTEEN_PREDICTION, getCanteenPrediction);
// }

// const getCanteenPredictionAsync = async (data) => {
//   return CanteenService.getCanteenPrediction(data);
// };

// function* getCanteenPrediction({ payload }) {
//   try {
//     const response = yield call(getCanteenPredictionAsync, payload);
//     if (response.data.success) {
//       yield put(getCanteenPredictionSuccess({ ...response.data.data, canteenId: payload.izyCanteenId }));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getCanteenPredictionError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getCanteenPredictionError(error.response.data.message));
//   }
// }



//Crockery

// export function* watchAddCrockery() {
//   yield takeEvery(ADD_CROCKERY, addCrockery);
// }

// const addCrockeryAsync = async (data) => {
//   return CanteenService.addCrockery(data);
// };

// function* addCrockery({ payload }) {
//   try {
//     const response = yield call(addCrockeryAsync, payload.crockeryData);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(addCrockerySuccess(response.data.success, response.data.message));
//       yield put(getCanteenData(payload.crockeryData.canteenId));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(addCrockeryError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(addCrockeryError(error.response.data.message));
//   }
// }



// export function* watchDeleteCrockery() {
//   yield takeEvery(DELETE_CROCKERY, deleteCrockery);
// }

// const deleteCrockeryAsync = async (id) => {
//   return CanteenService.deleteCrockery(id);
// };

// function* deleteCrockery({ payload }) {
//   try {
//     const response = yield call(deleteCrockeryAsync, payload.crockeryData.crockeryId);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         deleteCrockerySuccess(response.data.success, response.data.message)
//       );
//       yield put(getCanteenData(payload.crockeryData.canteenId));
//     } else {
//       yield put(deleteCrockeryError(response.data.message));
//     }
//   } catch (error) {
//     yield put(deleteCrockeryError(error.response.data.message));
//   }
// }

// export function* watchEditCrockery() {
//   yield takeEvery(EDIT_CROCKERY, editCrockery);
// }

// const editCrockeryAsync = async (data, id) => {
//   return CanteenService.editCrockery(data, id);
// };

// function* editCrockery({ payload }) {
//   try {
//     const response = yield call(
//       editCrockeryAsync,
//       payload.crockeryData,
//       payload.crockeryId
//     );
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         editCrockerySuccess(response.data.success, response.data.message)
//       );
//       yield put(getCanteenData(payload.crockeryData.canteenId));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(editCrockeryError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(editCrockeryError(error.response.data.message));
//   }
// }


// Product-crockery association


// export function* watchGetWeightedList() {
//   yield takeEvery(GET_WEIGHTED_LIST, getWeightedList);
// }

// const getWeightedListAsync = async (id) => {
//   return CanteenService.getWeightedList(id);
// };

// function* getWeightedList({ payload }) {
//   try {
//     const response = yield call(
//       getWeightedListAsync,
//       payload.canteenId,
//     );
//     if (response.data.success) {
//       yield put(getWeightedListSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getWeightedListError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getWeightedListError(error.response.data.message));
//   }
// }


// export function* watchEditWeightedList() {
//   yield takeEvery(EDIT_WEIGHTED_LIST, editWeightedList);
// }

// const editWeightedListAsync = async (data, id) => {
//   return CanteenService.editWeightedList(data, id);
// };

// function* editWeightedList({ payload }) {
//   try {
//     const response = yield call(
//       editWeightedListAsync,
//       payload.crockeryData,
//       payload.productId
//     );
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         editWeightedListSuccess(response.data.success, response.data.message)
//       );
//       yield put(getWeightedListData(payload.crockeryData.canteenId));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(editWeightedListError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(editCrockeryError(error.response.data.message));
//   }
// }


// export function* watchExportCanteenProducts() {
//   yield takeEvery(EXPORT_CANTEEN_PRODUCTS, exportCanteenProducts);
// }

// const exportCanteenProductsAsync = async (payload) => {
//   return CanteenService.exportCanteenProducts(payload);
// };

// function* exportCanteenProducts({ payload }) {
//   try {
//     const response = yield call(exportCanteenProductsAsync, payload?.canteenId);
//     if (response && response.data) {
//       yield put(exportCanteenProductsSuccess(true, ""));
//       const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
//       const date = moment().format('YYYY.MM.DD HH:mm:ss')
//       saveAs(blob, `(${payload?.canteenName}) Products-${date}.xlsx`);
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(exportCanteenProductsError(response.data.message));
//     }
//   } catch (error) {
//     const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
//     toast.error(
//       <ToastElement type="error" message={err.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(exportCanteenProductsError(err.message));
//   }
// }



// export function* watchGetDefaultCanteenSettings() {
//   yield takeEvery(GET_DEFAULT_CANTEEN_SETTINGS, getDefaultCanteenSettings);
// }

// const getDefaultCanteenSettingsAsync = async (id) => {
//   return CanteenService.getDefaultCanteenSettings(id);
// };

// function* getDefaultCanteenSettings({ payload }) {
//   try {
//     const response = yield call(getDefaultCanteenSettingsAsync, payload.canteenId);
//     if (response.data.success) {
//       yield put(getDefaultCanteenSettingsSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getDefaultCanteenSettingsError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getDefaultCanteenSettingsError(error.response.data.message));
//   }
// }


// export function* watchGetListCanteenSettings() {
//   yield takeEvery(GET_LIST_CANTEEN_SETTINGS, getListCanteenSettingsAc);
// }

// const getListCanteenSettingsAsync = async (id) => {
//   return CanteenService.getListCanteenSettings(id);
// };

// function* getListCanteenSettingsAc({ payload }) {
//   try {
//     const response = yield call(getListCanteenSettingsAsync, payload.canteenId);
//     if (response.data.success) {
//       yield put(getListCanteenSettingsSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(getListCanteenSettingsError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(getListCanteenSettingsError(error.response.data.message));
//   }
// }


// export function* watchDeletePOSCanteenSettings() {
//   yield takeEvery(DELETE_POS_CANTEEN_SETTINGS, deletePOSCanteenSettings);
// }

// const deletePOSCanteenSettingsAsync = async (id, posId) => {
//   return CanteenService.deletePOSCanteenSettings(id, posId);
// };

// function* deletePOSCanteenSettings({ payload }) {
//   try {
//     const response = yield call(deletePOSCanteenSettingsAsync, payload.canteenId, payload.posId);
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         deletePOSCanteenSettingsSuccess(response.data.success, response.data.message, payload.posId)
//       );
//     } else {
//       yield put(deletePOSCanteenSettingsError(response.data.message));
//     }
//   } catch (error) {
//     yield put(deletePOSCanteenSettingsError(error.response.data.message));
//   }
// }



// export function* watchSetDefaultCanteenSettings() {
//   yield takeEvery(SET_DEFAULT_CANTEEN_SETTINGS, setDefaultCanteenSettings);
// }

// const setDefaultCanteenSettingsAsync = async (data, id) => {
//   if (data?.posId !== null) {
//     const val = {
//       canteenId: data.canteenId,
//       posSettings: [data]
//     }
//     return CanteenService.setPOSCanteenSettings(val)
//   }
//   return CanteenService.setDefaultCanteenSettings(data, id);
// };

// function* setDefaultCanteenSettings({ payload }) {
//   try {
//     const response = yield call(
//       setDefaultCanteenSettingsAsync,
//       payload.settingData,
//       payload.canteenId
//     );
//     if (response.data.success) {
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         setDefaultCanteenSettingsSuccess(response.data.success, response.data.message, payload.settingData)
//       );
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(setDefaultCanteenSettingsError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(setDefaultCanteenSettingsError(error.response.data.message));
//   }
// }



// export function* watchSetCanteenLocalPrice() {
//   yield takeEvery(SET_CANTEEN_LOCAL_PRICE, setCanteenLocalPrice);
// }

// const setCanteenLocalPriceAsync = async (data) => {

//   return CanteenService.setCanteenLocalPrice(data);
// };

// function* setCanteenLocalPrice({ payload }) {
//   try {
//     const response = yield call(setCanteenLocalPriceAsync, payload.priceData);
//     if (response.data.success) {
//       payload?.callback()
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         setCanteenLocalPriceSuccess(response.data.success, response.data.message, payload.priceData)
//       );
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(setCanteenLocalPriceError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(setCanteenLocalPriceError(error.response.data.message));
//   }
// }



// export function* watchResetToGlobalPricing() {
//   yield takeEvery(RESET_TO_GLOBAL_PRICES, resetToGlobalPricing);
// }

// const resetToGlobalPricingAsync = async (data) => {

//   return CanteenService.resetToGlobalPricing(data);
// };

// function* resetToGlobalPricing({ payload }) {
//   try {
//     const response = yield call(resetToGlobalPricingAsync, payload.resetData);
//     if (response.data.success) {
//       payload?.callback()
//       toast.success(
//         <ToastElement type="success" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         resetToGlobalPricingSuccess(response.data.success, response.data.message, payload.resetData)
//       );
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(resetToGlobalPricingError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(resetToGlobalPricingError(error.response.data.message));
//   }
// }


// export function* watchUpdateCanteenProductPrices() {
//   yield takeEvery(UPDATE_CANTEEN_PRODUCT_PRICES, updateCanteenProductPrices);
// }

// const updateCanteenProductPricesAsync = async (data) => {

//   return CanteenService.updateCanteenProductPrices(data);
// };

// function* updateCanteenProductPrices({ payload }) {
//   try {
//     const response = yield call(updateCanteenProductPricesAsync, payload.canteenData);
//     if (response.data.success) {
//       payload?.callback?.()
//       toast.success(
//         <ToastElement type="success" message={response?.data?.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(
//         updateCanteenProductPricesSuccess(response?.data?.success, response?.data?.message, response?.data?.data)
//       );
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response?.data?.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//       yield put(updateCanteenProductPricesError(response?.data?.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement
//         type="error"
//         message={parseMessage(
//           error?.response?.data?.errors
//             ? error?.response?.data?.errors
//             : error?.response?.data?.message
//         )}
//       />,
//       { containerId: "default", position: "bottom-right" }
//     );
//     yield put(updateCanteenProductPricesError(error?.response?.data?.message));
//   }
// }


// // Subsidy Agreements
// export function* watchGetSubsidyAgreements() {
//   yield takeEvery(GET_SUBSIDY_AGREEMENTS, getSubsidyAgreements);
// }

// const getSubsidyAgreementsAsync = async (canteenId) => {
//   return CanteenService.getSubsidyAgreements(canteenId);
// };

// function* getSubsidyAgreements({ payload }) {
//   try {
//     const response = yield call(getSubsidyAgreementsAsync, payload.canteenId);
//     if (response.data.success) {
//       yield put(getSubsidyAgreementsSuccess(response.data.data));
//     } else {
//       yield put(getSubsidyAgreementsError(response.data.message));
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//     }
//   } catch (error) {
//     yield put(getSubsidyAgreementsError(error.response.data.message));
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//   }
// }


// export function* watchAddSubsidyAgreement() {
//   yield takeEvery(ADD_SUBSIDY_AGREEMENT, addSubsidyAgreement);
// }

// const addSubsidyAgreementAsync = async (data) => {
//   return CanteenService.addSubsidyAgreement(data);
// };

// function* addSubsidyAgreement({ payload }) {
//   try {
//     const response = yield call(addSubsidyAgreementAsync, payload.subsidyData);
//     if (response.data.success) {
//       payload?.callback?.()
//       yield put(addSubsidyAgreementSuccess(response.data.success, response.data.message, response.data.data));
//     } else {
//       yield put(addSubsidyAgreementError(response.data.message));
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//     }
//   } catch (error) {
//     yield put(addSubsidyAgreementError(error.response.data.message));
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//   }
// }


// export function* watchGetSubsidyAgreementDetails() {
//   yield takeEvery(GET_SUBSIDY_AGREEMENT_DETAILS, getSubsidyAgreementDetails);
// }

// const getSubsidyAgreementDetailsAsync = async (subsidyId) => {
//   return CanteenService.getSubsidyAgreementDetails(subsidyId);
// };

// function* getSubsidyAgreementDetails({ payload }) {
//   try {
//     const response = yield call(getSubsidyAgreementDetailsAsync, payload.subsidyId);
//     if (response.data.success) {
//       payload?.callback?.(response.data.data)
//       yield put(getSubsidyAgreementDetailsSuccess(response.data.data));
//     } else {
//       yield put(getSubsidyAgreementDetailsError(response.data.message));
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//     }
//   } catch (error) {
//     yield put(getSubsidyAgreementDetailsError(error.response.data.message));
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//   }
// }


// export function* watchDeleteSubsidyAgreement() {
//   yield takeEvery(DELETE_SUBSIDY_AGREEMENT, deleteSubsidyAgreement);
// }

// const deleteSubsidyAgreementAsync = async (subsidyId) => {
//   return CanteenService.deleteSubsidyAgreement(subsidyId);
// };

// function* deleteSubsidyAgreement({ payload }) {
//   try {
//     const response = yield call(deleteSubsidyAgreementAsync, payload.subsidyId);
//     if (response.data.success) {
//       payload?.callback?.()
//       yield put(deleteSubsidyAgreementSuccess(response.data.success, response.data.message, payload.subsidyId));
//     } else {
//       yield put(deleteSubsidyAgreementError(response.data.message));
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default", position: "bottom-right" }
//       );
//     }
//   } catch (error) {
//     yield put(deleteSubsidyAgreementError(error.response.data.message));
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default", position: "bottom-right" }
//     );
//   }
// }


// export default function* rootSaga() {
//   yield all([
//     fork(watchGetAllCanteen),
//     fork(watchGetCanteenList),
//     fork(watchAddCanteen),
//     fork(watchGetCanteen),
//     fork(watchEditCanteen),
//     fork(watchDeleteCanteen),
//     fork(watchDeleteMultipleCanteen),
//     fork(watchGetCanteenPrediction),
//     fork(watchAddCrockery),
//     fork(watchDeleteCrockery),
//     fork(watchEditCrockery),
//     fork(watchGetWeightedList),
//     fork(watchEditWeightedList),
//     fork(watchExportCanteenProducts),
//     fork(watchGetDefaultCanteenSettings),
//     fork(watchGetListCanteenSettings),
//     fork(watchSetDefaultCanteenSettings),
//     fork(watchDeletePOSCanteenSettings),
//     fork(watchSetCanteenLocalPrice),
//     fork(watchResetToGlobalPricing),
//     fork(watchUpdateCanteenProductPrices),
//     fork(watchGetSubsidyAgreements),
//     fork(watchAddSubsidyAgreement),
//     fork(watchGetSubsidyAgreementDetails),
//     fork(watchDeleteSubsidyAgreement),
//   ]);
// }

import rootSaga from "./sagas";

export default rootSaga;