import { all, fork } from "redux-saga/effects";
import {
    watchAddCanteen,
    watchDeleteCanteen,
    watchDeleteMultipleCanteen,
    watchEditCanteen,
    watchGetAllCanteen,
    watchGetCanteen,
    watchGetCanteenList,
} from "./canteen";
import {
    watchAddCrockery,
    watchDeleteCrockery,
    watchEditCrockery,
} from "./crockery";
import { watchExportCanteenProducts } from "./export";
import { watchGetCanteenPrediction } from "./prediction";
import {
    watchResetToGlobalPricing,
    watchSetCanteenLocalPrice,
    watchUpdateCanteenProductPrices,
} from "./pricing";
import {
    watchDeletePOSCanteenSettings,
    watchGetDefaultCanteenSettings,
    watchGetListCanteenSettings,
    watchSetDefaultCanteenSettings,
} from "./settings";
import {
    watchAddSubsidyAgreement,
    watchDeleteSubsidyAgreement,
    watchGetSubsidyAgreementDetails,
    watchGetSubsidyAgreements,
} from "./subsidy";
import {
    watchEditWeightedList,
    watchGetWeightedList,
} from "./weighted-list";


export default function* rootSaga() {
    yield all([
        // Canteen
        fork(watchGetAllCanteen),
        fork(watchGetCanteenList),
        fork(watchAddCanteen),
        fork(watchGetCanteen),
        fork(watchEditCanteen),
        fork(watchDeleteCanteen),
        fork(watchDeleteMultipleCanteen),

        // Prediction
        fork(watchGetCanteenPrediction),

        // Crockery
        fork(watchAddCrockery),
        fork(watchDeleteCrockery),
        fork(watchEditCrockery),

        // Weighted List
        fork(watchGetWeightedList),
        fork(watchEditWeightedList),

        // Export
        fork(watchExportCanteenProducts),

        // Settings
        fork(watchGetDefaultCanteenSettings),
        fork(watchGetListCanteenSettings),
        fork(watchSetDefaultCanteenSettings),
        fork(watchDeletePOSCanteenSettings),
        fork(watchSetCanteenLocalPrice),
        fork(watchResetToGlobalPricing),
        fork(watchUpdateCanteenProductPrices),

        // Subsidy
        fork(watchGetSubsidyAgreements),
        fork(watchAddSubsidyAgreement),
        fork(watchGetSubsidyAgreementDetails),
        fork(watchDeleteSubsidyAgreement),
    ]);
}
