import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import { parseMessage } from "../../../helpers/util";
import CanteenService from "../../../services/canteen-service";
import * as types from "../types";
import {
    editWeightedListError,
    editWeightedListSuccess,
    getWeightedList as getWeightedListData,
    getWeightedListError,
    getWeightedListSuccess,
    editCrockeryError
} from "../action";


export function* watchGetWeightedList() {
    yield takeEvery(types.GET_WEIGHTED_LIST, getWeightedList);
}

const getWeightedListAsync = async (id) => {
    return CanteenService.getWeightedList(id);
};

function* getWeightedList({ payload }) {
    try {
        const response = yield call(
            getWeightedListAsync,
            payload.canteenId,
        );
        if (response.data.success) {
            yield put(getWeightedListSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getWeightedListError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getWeightedListError(error.response.data.message));
    }
}


export function* watchEditWeightedList() {
    yield takeEvery(types.EDIT_WEIGHTED_LIST, editWeightedList);
}

const editWeightedListAsync = async (data, id) => {
    return CanteenService.editWeightedList(data, id);
};

function* editWeightedList({ payload }) {
    try {
        const response = yield call(
            editWeightedListAsync,
            payload.crockeryData,
            payload.productId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                editWeightedListSuccess(response.data.success, response.data.message)
            );
            yield put(getWeightedListData(payload.crockeryData.canteenId));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(editWeightedListError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(editCrockeryError(error.response.data.message));
    }
}
