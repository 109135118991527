import * as types from '../types';


// Canteen Local Price
export const setCanteenLocalPrice = (priceData, callback) => ({
    type: types.SET_CANTEEN_LOCAL_PRICE,
    payload: { priceData, callback },
});

export const setCanteenLocalPriceSuccess = (success, message, priceData) => ({
    type: types.SET_CANTEEN_LOCAL_PRICE_SUCCESS,
    payload: { success, message, priceData },
});

export const setCanteenLocalPriceError = (error) => ({
    type: types.SET_CANTEEN_LOCAL_PRICE_ERROR,
    payload: error,
});


export const resetToGlobalPricing = (resetData, callback) => ({
    type: types.RESET_TO_GLOBAL_PRICES,
    payload: { resetData, callback },
});

export const resetToGlobalPricingSuccess = (success, message, resetData) => ({
    type: types.RESET_TO_GLOBAL_PRICES_SUCCESS,
    payload: { success, message, resetData },
});

export const resetToGlobalPricingError = (error) => ({
    type: types.RESET_TO_GLOBAL_PRICES_ERROR,
    payload: error,
});

export const updateCanteenProductPrices = (canteenData, callback) => ({
    type: types.UPDATE_CANTEEN_PRODUCT_PRICES,
    payload: { canteenData, callback },
});

export const updateCanteenProductPricesSuccess = (success, message, canteenData) => ({
    type: types.UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS,
    payload: { success, message, canteenData },
});

export const updateCanteenProductPricesError = (error) => ({
    type: types.UPDATE_CANTEEN_PRODUCT_PRICES_ERROR,
    payload: error,
});
