import {
    SET_CANTEEN_LOCAL_PRICE,
    SET_CANTEEN_LOCAL_PRICE_SUCCESS,
    SET_CANTEEN_LOCAL_PRICE_ERROR,
    RESET_TO_GLOBAL_PRICES,
    RESET_TO_GLOBAL_PRICES_SUCCESS,
    RESET_TO_GLOBAL_PRICES_ERROR,
    UPDATE_CANTEEN_PRODUCT_PRICES,
    UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS,
    UPDATE_CANTEEN_PRODUCT_PRICES_ERROR,
} from '../types';
import { updateCanteenProductPrices, resetToGlobalPrices, updateCanteenProducts } from '../helpers/pricing';

export const pricingReducer = (state, action) => {
    switch (action.type) {
        case SET_CANTEEN_LOCAL_PRICE:
            return { ...state, loading2: true, error: null };
        case SET_CANTEEN_LOCAL_PRICE_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                canteenData: updateCanteenProductPrices(state, action.payload.priceData),
                error: null,
            };
        case SET_CANTEEN_LOCAL_PRICE_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case RESET_TO_GLOBAL_PRICES:
            return { ...state, loading2: true, error: null };
        case RESET_TO_GLOBAL_PRICES_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                canteenData: resetToGlobalPrices(state, action.payload.resetData),
                error: null,
            };
        case RESET_TO_GLOBAL_PRICES_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };
        case UPDATE_CANTEEN_PRODUCT_PRICES:
            return { ...state, loading2: true, error: null };
        case UPDATE_CANTEEN_PRODUCT_PRICES_SUCCESS:
            return {
                ...state,
                loading2: false,
                success: action.payload.success,
                message: action.payload.message,
                canteenData: updateCanteenProducts(state, action.payload.canteenData),
                error: null,
            };
        case UPDATE_CANTEEN_PRODUCT_PRICES_ERROR:
            return {
                ...state,
                loading2: false,
                success: false,
                message: null,
                error: action.payload,
            };

        default:
            return null;
    }
}; 