import {
    EXPORT_CANTEEN_PRODUCTS,
    EXPORT_CANTEEN_PRODUCTS_SUCCESS,
    EXPORT_CANTEEN_PRODUCTS_ERROR,
} from '../types';

export const exportReducer = (state, action) => {
    switch (action.type) {
        case EXPORT_CANTEEN_PRODUCTS:
            return { ...state, loading1: true, success: false, error: null };
        case EXPORT_CANTEEN_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading1: false,
                success: action.payload.success,
                message: action.payload.message,
                error: null,
            };
        case EXPORT_CANTEEN_PRODUCTS_ERROR:
            return {
                ...state,
                loading1: false,
                success: false,
                message: null,
                error: action.payload,
            };
        default:
            return null;
    }
}