import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import { parseMessage } from "../../../helpers/util";
import CanteenService from "../../../services/canteen-service";
import {
    SET_CANTEEN_LOCAL_PRICE,
    RESET_TO_GLOBAL_PRICES,
    UPDATE_CANTEEN_PRODUCT_PRICES,
} from "../types";
import {
    resetToGlobalPricingError,
    resetToGlobalPricingSuccess,
    setCanteenLocalPriceError,
    setCanteenLocalPriceSuccess,
    updateCanteenProductPricesError,
    updateCanteenProductPricesSuccess,
} from "../action";

export function* watchSetCanteenLocalPrice() {
    yield takeEvery(SET_CANTEEN_LOCAL_PRICE, setCanteenLocalPrice);
}

const setCanteenLocalPriceAsync = async (data) => {

    return CanteenService.setCanteenLocalPrice(data);
};

function* setCanteenLocalPrice({ payload }) {
    try {
        const response = yield call(setCanteenLocalPriceAsync, payload.priceData);
        if (response.data.success) {
            payload?.callback()
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                setCanteenLocalPriceSuccess(response.data.success, response.data.message, payload.priceData)
            );
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(setCanteenLocalPriceError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(setCanteenLocalPriceError(error.response.data.message));
    }
}



export function* watchResetToGlobalPricing() {
    yield takeEvery(RESET_TO_GLOBAL_PRICES, resetToGlobalPricing);
}

const resetToGlobalPricingAsync = async (data) => {

    return CanteenService.resetToGlobalPricing(data);
};

function* resetToGlobalPricing({ payload }) {
    try {
        const response = yield call(resetToGlobalPricingAsync, payload.resetData);
        if (response.data.success) {
            payload?.callback()
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                resetToGlobalPricingSuccess(response.data.success, response.data.message, payload.resetData)
            );
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(resetToGlobalPricingError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(resetToGlobalPricingError(error.response.data.message));
    }
}


export function* watchUpdateCanteenProductPrices() {
    yield takeEvery(UPDATE_CANTEEN_PRODUCT_PRICES, updateCanteenProductPrices);
}

const updateCanteenProductPricesAsync = async (data) => {

    return CanteenService.updateCanteenProductPrices(data);
};

function* updateCanteenProductPrices({ payload }) {
    try {
        const response = yield call(updateCanteenProductPricesAsync, payload.canteenData);
        if (response.data.success) {
            payload?.callback?.()
            toast.success(
                <ToastElement type="success" message={response?.data?.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                updateCanteenProductPricesSuccess(response?.data?.success, response?.data?.message, response?.data?.data)
            );
        } else {
            toast.error(
                <ToastElement type="error" message={response?.data?.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(updateCanteenProductPricesError(response?.data?.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error?.response?.data?.errors
                        ? error?.response?.data?.errors
                        : error?.response?.data?.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(updateCanteenProductPricesError(error?.response?.data?.message));
    }
}
