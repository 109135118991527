import * as types from '../types';

export const getAllCanteen = () => ({
    type: types.GET_ALL_CANTEEN,
    payload: {},
});

export const getAllCanteenSuccess = (canteens) => ({
    type: types.GET_ALL_CANTEEN_SUCCESS,
    payload: canteens,
});

export const getAllCanteenError = (error) => ({
    type: types.GET_ALL_CANTEEN_ERROR,
    payload: error,
});

export const getCanteenList = (dbParam) => ({
    type: types.GET_CANTEEN_LIST,
    payload: { dbParam },
});

export const getCanteenListSuccess = (canteenList) => ({
    type: types.GET_CANTEEN_LIST_SUCCESS,
    payload: canteenList,
});

export const getCanteenListError = (error) => ({
    type: types.GET_CANTEEN_LIST_ERROR,
    payload: error,
});

export const addCanteen = (canteenData, history, location) => ({
    type: types.ADD_CANTEEN,
    payload: { canteenData, history, location },
});

export const addCanteenSuccess = (success, message) => ({
    type: types.ADD_CANTEEN_SUCCESS,
    payload: { success, message },
});

export const addCanteenError = (error) => ({
    type: types.ADD_CANTEEN_ERROR,
    payload: error,
});

export const getCanteen = (canteenId, organizationId) => ({
    type: types.GET_CANTEEN,
    payload: { canteenId, organizationId },
});

export const getCanteenSuccess = (canteenData) => ({
    type: types.GET_CANTEEN_SUCCESS,
    payload: canteenData,
});

export const getCanteenError = (error) => ({
    type: types.GET_CANTEEN_ERROR,
    payload: error,
});

export const editCanteen = (canteenId, canteenData, history, location) => ({
    type: types.EDIT_CANTEEN,
    payload: { canteenId, canteenData, history, location },
});

export const editCanteenSuccess = (success, message) => ({
    type: types.EDIT_CANTEEN_SUCCESS,
    payload: { success, message },
});

export const editCanteenError = (error) => ({
    type: types.EDIT_CANTEEN_ERROR,
    payload: error,
});

export const deleteCanteen = (canteenId) => ({
    type: types.DELETE_CANTEEN,
    payload: { canteenId },
});

export const deleteCanteenSuccess = (success, message) => ({
    type: types.DELETE_CANTEEN_SUCCESS,
    payload: { success, message },
});

export const deleteCanteenError = (error) => ({
    type: types.DELETE_CANTEEN_ERROR,
    payload: error,
});

export const deleteMultipleCanteen = (canteenIds) => ({
    type: types.DELETE_MULTIPLE_CANTEEN,
    payload: { canteenIds },
});

export const deleteMultipleCanteenSuccess = (success, message) => ({
    type: types.DELETE_MULTIPLE_CANTEEN_SUCCESS,
    payload: { success, message },
});

export const deleteMultipleCanteenError = (error) => ({
    type: types.DELETE_MULTIPLE_CANTEEN_ERROR,
    payload: error,
});

export const resetCanteen = () => ({
    type: types.RESET_CANTEEN,
    payload: {},
});