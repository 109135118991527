import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import CanteenService from "../../../services/canteen-service";
import {
    EXPORT_CANTEEN_PRODUCTS
} from "../types";
import moment from "moment";
import { saveAs } from "file-saver";
import { exportCanteenProductsError, exportCanteenProductsSuccess } from "../actions";



export function* watchExportCanteenProducts() {
    yield takeEvery(EXPORT_CANTEEN_PRODUCTS, exportCanteenProducts);
}

const exportCanteenProductsAsync = async (payload) => {
    return CanteenService.exportCanteenProducts(payload);
};

function* exportCanteenProducts({ payload }) {
    try {
        const response = yield call(exportCanteenProductsAsync, payload?.canteenId);
        if (response && response.data) {
            yield put(exportCanteenProductsSuccess(true, ""));
            const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const date = moment().format('YYYY.MM.DD HH:mm:ss')
            saveAs(blob, `(${payload?.canteenName}) Products-${date}.xlsx`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(exportCanteenProductsError(response.data.message));
        }
    } catch (error) {
        const err = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
        toast.error(
            <ToastElement type="error" message={err.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(exportCanteenProductsError(err.message));
    }
}
