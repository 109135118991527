//Canteen Settings
export const updateListCanteenSettings = (state, settingData) => {
    if (state.listCanteenSettings?.find(item => item.posId === settingData?.posId)) {
        return state.listCanteenSettings?.map((item) => {
            if (item.posId === settingData.posId) {
                return { ...item, ...settingData }
            }
            return item
        })
    }
    return [...state?.listCanteenSettings, settingData]

}

export const deleteFromListCanteenSettings = (state, posId) => {

    return state.listCanteenSettings?.filter((item) => {

        return item.posId !== posId
    })
}