import * as types from '../types';

export const getCanteenPrediction = (dbParam) => ({
    type: types.GET_CANTEEN_PREDICTION,
    payload: dbParam,
});

export const getCanteenPredictionSuccess = (predictionData) => ({
    type: types.GET_CANTEEN_PREDICTION_SUCCESS,
    payload: predictionData,
});

export const getCanteenPredictionError = (error) => ({
    type: types.GET_CANTEEN_PREDICTION_ERROR,
    payload: error,
});
