import React from "react";

const IconExpandedMenu = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M4.5 6.02539L8.5 10.0254L12.5 6.02539"
        stroke={props.stroke || "#FEFEFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconExpandedMenu;
