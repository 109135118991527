import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import { parseMessage } from "../../../helpers/util";
import CanteenService from "../../../services/canteen-service";
import TableDataService from "../../../services/table-data-service";
import * as types from "../types";
import {
    addCanteenError,
    addCanteenSuccess,
    deleteCanteenError,
    deleteCanteenSuccess,
    deleteMultipleCanteenError,
    deleteMultipleCanteenSuccess,
    editCanteenError,
    editCanteenSuccess,
    getAllCanteenError,
    getAllCanteenSuccess,
    getCanteenError,
    getCanteenList,
    getCanteenListError,
    getCanteenListSuccess,
    getCanteenSuccess,
} from "../action";



export function* watchGetAllCanteen() {
    yield takeEvery(types.GET_ALL_CANTEEN, getAllCanteen);
}

const getAllCanteenAsync = async () => {
    return CanteenService.getAllCanteen();
};

function* getAllCanteen() {
    try {
        const response = yield call(getAllCanteenAsync);
        if (response.data.success) {
            yield put(getAllCanteenSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getAllCanteenError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getAllCanteenError(error.response.data.message));
    }
}

export function* watchGetCanteenList() {
    yield takeEvery(types.GET_CANTEEN_LIST, getCanteenListAc);
}

const getCanteenListAsync = async (dbParam) => {
    return TableDataService.getAllData(
        "canteens",
        dbParam?.orgId || "",
        dbParam?.search || "",
        dbParam?.searchFields || "",
        dbParam?.sortOrder || "",
        dbParam?.page || 1,
        dbParam?.pageSize || 10,
        dbParam?.activeCol || "",
        dbParam?.statusId || null,
        dbParam?.buildingId || null
    );
};

function* getCanteenListAc({ payload }) {
    try {
        const response = yield call(getCanteenListAsync, payload.dbParam);
        if (response.data.success) {
            yield put(getCanteenListSuccess(response.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getCanteenListError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getCanteenListError(error.response.data.message));
    }
}

export function* watchAddCanteen() {
    yield takeEvery(types.ADD_CANTEEN, addCanteen);
}

const addCanteenAsync = async (data) => {
    return CanteenService.addCanteen(data);
};

function* addCanteen({ payload }) {
    const { history, location } = payload;
    try {
        const response = yield call(addCanteenAsync, payload.canteenData);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                addCanteenSuccess(response.data.success, response.data.message)
            );
            history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(addCanteenError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(addCanteenError(error.response.data.message));
    }
}

export function* watchGetCanteen() {
    yield takeEvery(types.GET_CANTEEN, getCanteen);
}

const getCanteenAsync = async (id, organizationId) => {
    return CanteenService.getCanteen(id, organizationId);
};

function* getCanteen({ payload }) {
    try {
        const response = yield call(
            getCanteenAsync,
            payload.canteenId,
            payload.organizationId
        );
        if (response.data.success) {
            yield put(getCanteenSuccess(response.data.data));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(getCanteenError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement type="error" message={error.response.data.message} />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(getCanteenError(error.response.data.message));
    }
}

export function* watchEditCanteen() {
    yield takeEvery(types.EDIT_CANTEEN, editCanteen);
}

const editCanteenAsync = async (data, id) => {
    return CanteenService.editCanteen(data, id);
};

function* editCanteen({ payload }) {
    const { history, location } = payload;
    try {
        const response = yield call(
            editCanteenAsync,
            payload.canteenData,
            payload.canteenId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                editCanteenSuccess(response.data.success, response.data.message)
            );
            history.push(`/canteen-management/canteen${location?.state?.locationSearch ?? ""}`);
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(editCanteenError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(editCanteenError(error.response.data.message));
    }
}

export function* watchDeleteCanteen() {
    yield takeEvery(types.DELETE_CANTEEN, deleteCanteen);
}

const deleteCanteenAsync = async (id) => {
    return CanteenService.deleteCanteen(id);
};

function* deleteCanteen({ payload }) {
    try {
        const response = yield call(deleteCanteenAsync, payload.canteenId);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                deleteCanteenSuccess(response.data.success, response.data.message)
            );
            // Fetch updated canteen list
            yield put(getCanteenList({}));
        } else {
            yield put(deleteCanteenError(response.data.message));
        }
    } catch (error) {
        yield put(deleteCanteenError(error.response.data.message));
    }
}

export function* watchDeleteMultipleCanteen() {
    yield takeEvery(types.DELETE_MULTIPLE_CANTEEN, deleteMultipleCanteen);
}

const deleteMultipleCanteenAsync = async (ids) => {
    return CanteenService.deleteMultipleCanteen(ids);
};

function* deleteMultipleCanteen({ payload }) {
    try {
        const response = yield call(deleteMultipleCanteenAsync, payload.canteenIds);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                deleteMultipleCanteenSuccess(
                    response.data.success,
                    response.data.message
                )
            );
            // Fetch updated canteen list
            yield put(getCanteenList({}));
        } else {
            yield put(deleteMultipleCanteenError(response.data.message));
        }
    } catch (error) {
        yield put(deleteMultipleCanteenError(error.response.data.message));
    }
}