import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ToastElement from "../../../components/toast";
import { parseMessage } from "../../../helpers/util";
import CanteenService from "../../../services/canteen-service";
import * as types from "../types";
import {
    addCrockeryError,
    addCrockerySuccess,
    deleteCrockeryError,
    deleteCrockerySuccess,
    editCrockeryError,
    editCrockerySuccess,
    getCanteen as getCanteenData
} from "../action";

export function* watchAddCrockery() {
    yield takeEvery(types.ADD_CROCKERY, addCrockery);
}

const addCrockeryAsync = async (data) => {
    return CanteenService.addCrockery(data);
};

function* addCrockery({ payload }) {
    try {
        const response = yield call(addCrockeryAsync, payload.crockeryData);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(addCrockerySuccess(response.data.success, response.data.message));
            yield put(getCanteenData(payload.crockeryData.canteenId));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(addCrockeryError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(addCrockeryError(error.response.data.message));
    }
}



export function* watchDeleteCrockery() {
    yield takeEvery(types.DELETE_CROCKERY, deleteCrockery);
}

const deleteCrockeryAsync = async (id) => {
    return CanteenService.deleteCrockery(id);
};

function* deleteCrockery({ payload }) {
    try {
        const response = yield call(deleteCrockeryAsync, payload.crockeryData.crockeryId);
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                deleteCrockerySuccess(response.data.success, response.data.message)
            );
            yield put(getCanteenData(payload.crockeryData.canteenId));
        } else {
            yield put(deleteCrockeryError(response.data.message));
        }
    } catch (error) {
        yield put(deleteCrockeryError(error.response.data.message));
    }
}

export function* watchEditCrockery() {
    yield takeEvery(types.EDIT_CROCKERY, editCrockery);
}

const editCrockeryAsync = async (data, id) => {
    return CanteenService.editCrockery(data, id);
};

function* editCrockery({ payload }) {
    try {
        const response = yield call(
            editCrockeryAsync,
            payload.crockeryData,
            payload.crockeryId
        );
        if (response.data.success) {
            toast.success(
                <ToastElement type="success" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(
                editCrockerySuccess(response.data.success, response.data.message)
            );
            yield put(getCanteenData(payload.crockeryData.canteenId));
        } else {
            toast.error(
                <ToastElement type="error" message={response.data.message} />,
                { containerId: "default", position: "bottom-right" }
            );
            yield put(editCrockeryError(response.data.message));
        }
    } catch (error) {
        toast.error(
            <ToastElement
                type="error"
                message={parseMessage(
                    error.response.data.errors
                        ? error.response.data.errors
                        : error.response.data.message
                )}
            />,
            { containerId: "default", position: "bottom-right" }
        );
        yield put(editCrockeryError(error.response.data.message));
    }
}
